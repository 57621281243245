<template>
    <div class="account-menu-group">
        <div v-if="groupName" class="account-menu-group-name">{{ groupName }}</div>
        <div class="account-menu-group-items">
            <template v-for="(menuItem, index) in items">
                <template v-if="menuItem.subMenus">
                    <ExpansionPanel :key="menuItem.name" :title="menuItem.name" class="account-menu-expansion">
                        <template v-if="menuItem.icon" #icon>
                            <SvgIcon class="account-menu-icon" :icon-id="menuItem.icon" />
                        </template>
                        <MyAccountMenuItem
                            v-for="(subMenuItem, subMenuIdx) in menuItem.subMenus"
                            :key="subMenuItem.name + '-sub-menu-item-' + subMenuIdx"
                            :menu-item="{ ...subMenuItem, name: subMenuItem.name }"
                            @menuItemClicked="onAccountMenuItemSelect(subMenuItem)"
                        />
                    </ExpansionPanel>
                </template>
                <MyAccountMenuItem
                    v-else
                    :key="menuItem.name + '-menu-item-' + index"
                    :menu-item="{ ...menuItem, name: menuItem.name }"
                    @menuItemClicked="onAccountMenuItemSelect(menuItem)"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MyAccountMenuItem from '@/components/Fragments/MyAccount/MyAccountMenuItem.vue';
import { helper } from '@/modules/core';
import { strapiAccountMenuComponent } from '@/modules/platform/const/strapi';
import { action as platformAction } from '@/modules/platform/store/const';
import { getter as coreGetter } from '@/modules/core/store/const';
import { getter as translationGetter } from '@/store/modules/translations/const';

export default {
    name: 'MyAccountMenuGroup',
    components: { MyAccountMenuItem },
    props: {
        items: {
            type: Array,
        },
        groupName: {
            type: String,
        },
    },
    computed: {
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            nextAvailableLanguage: translationGetter.GET_NEXT_AVAILABLE_LANGUAGE,
        }),
        isLangUpdateInProgress() {
            return this.isLoading(platformAction.UPDATE_LANGUAGE_PREFERENCE);
        },
    },
    methods: {
        onAccountMenuItemSelect(menuItem) {
            if (menuItem.component === strapiAccountMenuComponent.LANGUAGE_SWITCHER) {
                this.switchLang();
                return;
            }
            if (menuItem.externalUrl) {
                helper.openUrl(menuItem.externalUrl, menuItem.target);
                return;
            }
            if (this.$route.path === menuItem.url) {
                this.$emit('closeMenu');
                return;
            }
            this.$router.push({ path: menuItem.url });
        },
        switchLang() {
            if (this.isLangUpdateInProgress) return;
            this.$store.dispatch(platformAction.CHANGE_SITE_LANGUAGE, {
                language: this.nextAvailableLanguage,
                component: 'MyAccount',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'my-account.mixin';

.account-menu-group {
    margin-top: 12px;

    &-name {
        @extend %body-normal-font-500;
        color: #8e9499;
        margin-bottom: 4px;
    }
    &-items {
        ::v-deep .account-menu-expansion {
            @extend %menu-item-border-radius;
            border: 1px solid transparent;
            border-bottom: 0;
            overflow: hidden;
            box-sizing: border-box;
            margin-bottom: 2px;
            .icon-arrow-up {
                width: 16px;
                height: 12px;
            }
            .account-menu-icon {
                width: 16px;
                height: 16px;
                margin-right: 8px;
                fill: $dark-grey-2;
            }
            &:has(.expansion-panel-content) {
                border: 1px solid $light-green;
                box-sizing: border-box;
                .account-menu-icon {
                    fill: $main-text;
                }
                .title {
                    background: #ddfaa5;
                }
            }
            .title {
                background: $white-bg;
                @extend %my-account-menu-item;
                padding: 11px;

                h3 {
                    @extend %body-normal-font-400;
                    min-height: unset;
                    text-transform: unset;
                }
            }
            .expansion-panel-content > div {
                box-sizing: border-box;
                margin-bottom: 2px;
            }
        }
        div {
            background: $white-bg;
        }
    }
}
</style>

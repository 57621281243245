<template>
    <div v-click-outside:[true]="closeMyAccountMenu" class="my-account-container">
        <div class="account-menu-header">
            <div class="close" data-test-id="account-menu-close" @click="closeMyAccountMenu">
                <SvgIcon icon-id="icon-cross-squared" vertical-align="top" />
            </div>
        </div>
        <div class="account-menu-cta-box">
            <UserPhoneOperator
                v-if="isAuthenticated"
                :is-phone-label-visible="false"
                :provider-icon="providerIcon"
                :show-verified-status="true"
                icon-size="very-large"
            >
                <div class="account-menu-actions">
                    <button
                        class="button button-primary"
                        data-test-id="account-menu-deposit-btn"
                        @click="navigateAction(routeName.DEPOSIT)"
                    >
                        {{ $t('ui.payment.deposit.button').toUpperCase() }}
                    </button>
                    <button
                        class="button button-outline"
                        data-test-id="account-menu-withdraw-btn"
                        @click="navigateAction(routeName.WITHDRAW)"
                    >
                        {{ $t('ui.payment.payout.requestWithdraw').toUpperCase() }}
                    </button>
                </div>
            </UserPhoneOperator>
            <div v-else class="account-menu-actions">
                <button class="button button-primary" data-test-id="account-menu-joinnow-btn" @click="navigateAction(routeName.JOIN_NOW)">
                    {{ $t('ui.common.joinNow').toUpperCase() }}
                </button>
                <button class="button button-outline" data-test-id="account-menu-login-btn" @click="navigateAction(routeName.LOGIN)">
                    {{ $t('ui.common.logIn').toUpperCase() }}
                </button>
            </div>
        </div>

        <div class="account-menu-contents">
            <template v-if="strapiNewAccountMenuLinks.length">
                <MyAccountMenuGroup
                    v-for="(menuGroup, index) in strapiNewAccountMenuLinks"
                    :key="menuGroup.groupName + '-menu-group-' + index"
                    :items="menuGroup.menuList"
                    :group-name="menuGroup.groupName"
                    @closeMenu="closeMyAccountMenu"
                />
            </template>
            <template v-else>
                <MyAccountMenuItem
                    v-for="(menuItem, index) in strapiOldAccountMenuLinks.length ? strapiOldAccountMenuLinks : fallbackAccountMenuLinks"
                    :key="menuItem.name + '-menu-item-' + index"
                    :menu-item="{
                        ...menuItem,
                        name: strapiOldAccountMenuLinks.length ? menuItem.name : $t(menuItem.key, { pinOrPassword }),
                    }"
                    @menuItemClicked="onAccountMenuItemSelect(menuItem)"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { deviceType, helper, processMenu } from '@/modules/core';

import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';

import { getter as translationGetter } from '@/store/modules/translations/const';

import { telcoIcon } from '@/modules/payment';
import { UserPhoneOperator } from '@/modules/payment/components';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import MyAccountMenuItem from '@/components/Fragments/MyAccount/MyAccountMenuItem.vue';
import { routeName } from '@/router/const-name';
import MyAccountMenuGroup from '@/components/Fragments/MyAccount/MyAccountMenuGroup.vue';
import { strapiAccountMenuComponent } from '@/modules/platform/const/strapi';

const DOWNLOAD_MENU_ITEM = 'android-app';

export default {
    name: 'MyAccount',
    components: { MyAccountMenuGroup, MyAccountMenuItem, UserPhoneOperator },
    mixins: [PageMixin, SEOMixin],
    data() {
        return {
            isWebOrAndroidWeb: deviceType.isWebOrAndroidWeb(),
            routeName,
        };
    },
    computed: {
        ...mapGetters({
            user: platformGetter.GET_USER_SETTINGS,
            preference: platformGetter.GET_PREFERENCE,
            pinOrPassword: translationGetter.PIN_OR_PASSWORD,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            settings: platformGetter.GET_SETTINGS,
            currentLanguage: translationGetter.GET_SELECTED_LANGUAGE,
            nextAvailableLanguage: translationGetter.GET_NEXT_AVAILABLE_LANGUAGE,
            currentUserStatus: platformGetter.GET_CURRENT_USER_STATUS,
            depositOptionByPhoneOperator: platformGetter.GET_DEPOSIT_OPTION_BY_PHONE_OPERATOR,
            depositOptionByPreferredPaymentProvider: platformGetter.GET_DEPOSIT_OPTION_BY_PREFERRED_PAYMENT_PROVIDER,
        }),
        ...mapState({
            fallbackAccountMenuLinks(state) {
                return state.menus.account
                    .map((item) => {
                        const newItem = processMenu.processDefaultItemBadge({ item, newMessagesNumber: this.newMessagesNumber });
                        return newItem.key === 'menus.changePassword'
                            ? { ...newItem, key: `ui.common.${this.pinOrPassword}.changePassword` }
                            : newItem;
                    })
                    .filter((item) => {
                        if (item.key === 'menus.myAccount') {
                            return;
                        }
                        if (item.enabler && !helper.pathChecker(item.enabler, this.settings)) {
                            return;
                        }
                        if (item.name === DOWNLOAD_MENU_ITEM && !this.isWebOrAndroidWeb) {
                            return;
                        }
                        return helper.processMenuAuth(item.requireAuth, this.isAuthenticated);
                    });
            },
            newMessagesNumber: (state) => state.platform.messaging.notSeen,
            // TODO: https://aliengain.atlassian.net/browse/BP-33284 remove strapiOldAccountMenuLinks and refactor strapiNewAccountMenuLinks, also refactor usage in template
            strapiNewAccountMenuLinks(state) {
                const menuGroups = helper.getObjectField(state.platform.config, 'accountMenu.menuGroups', []);
                return menuGroups.map((menu) => ({
                    groupName: menu.name,
                    menuList: this.reStructureAccountMenuLinks(this.processAccountMenuLinks(menu.links)),
                }));
            },
            strapiOldAccountMenuLinks(state) {
                const links = helper.getObjectField(state.platform.config, 'accountMenu.links', []);
                return this.processAccountMenuLinks(links);
            },
            userPhoneOperatorNetworkName() {
                return this.user.phoneOperatorNetwork || '';
            },
            preferredPaymentProviderName() {
                return this.preference.preferred_payment_provider || '';
            },
            depositChannelIcon() {
                return (
                    telcoIcon[(this.preferredPaymentProviderName || '').toUpperCase()] ||
                    telcoIcon[(this.userPhoneOperatorNetworkName || '').toUpperCase()] ||
                    telcoIcon.DEFAULT
                );
            },
            providerIcon() {
                const iconUrlFromDepositOptionInStrapi = helper.getObjectField(
                    this.depositOptionByPreferredPaymentProvider || this.depositOptionByPhoneOperator,
                    'attributes.icon.data.attributes.url'
                );
                return iconUrlFromDepositOptionInStrapi || `img/providers/${this.depositChannelIcon}.png`;
            },
        }),
    },
    methods: {
        onAccountMenuItemSelect(menuItem) {
            if (menuItem.externalUrl) {
                helper.openUrl(menuItem.externalUrl, menuItem.target);
                return;
            }
            if (this.$route.path === menuItem.url) {
                this.closeMyAccountMenu();
                return;
            }
            this.$router.push({ path: menuItem.url });
        },
        closeMyAccountMenu() {
            this.$store.commit('setAccountMenuState', false);
        },
        getLanguageSwitcherLinkAttrs() {
            return {
                name: this.$t('languageChangeText'),
                curFlagIcon: `icon-language-menu-${this.currentLanguage}`,
                nextFlagIcon: `icon-language-menu-${this.nextAvailableLanguage}`,
            };
        },
        processAccountMenuLinks(links) {
            const processedStrapiMenuLinks = helper.processStrapiMenuLinks({
                links,
                userStatus: this.currentUserStatus,
                isAuthenticated: this.isAuthenticated,
                messagesCount: this.newMessagesNumber,
                router: this.$router,
            });

            const languageSwitcherComponentLinkIndex = processedStrapiMenuLinks.findIndex(
                (link) => link.component && link.component === strapiAccountMenuComponent.LANGUAGE_SWITCHER
            );

            if (languageSwitcherComponentLinkIndex !== -1) {
                processedStrapiMenuLinks[languageSwitcherComponentLinkIndex] = {
                    ...processedStrapiMenuLinks[languageSwitcherComponentLinkIndex],
                    ...this.getLanguageSwitcherLinkAttrs(),
                };
            }

            return processedStrapiMenuLinks;
        },
        reStructureAccountMenuLinks(menuList) {
            const parentLinks = {};
            const subMenus = {};
            const restructuredMenuLinks = [];

            menuList.forEach((link, index) => {
                const { linkCategoryName, id, isParent } = link;
                link.position = index;

                if (isParent) {
                    parentLinks[id] = { ...link, subMenus: [] };
                } else if (linkCategoryName) {
                    subMenus[linkCategoryName] = subMenus[linkCategoryName] || [];
                    subMenus[linkCategoryName].push(link);
                } else {
                    restructuredMenuLinks.push(link);
                }
            });

            // Attach submenu links to their respective parent links and push parent link to restructuredMenuLinks
            Object.values(parentLinks).forEach((parent) => {
                const { linkCategoryName } = parent;
                if (linkCategoryName && subMenus[linkCategoryName]) {
                    parent.subMenus = subMenus[linkCategoryName];
                }
                restructuredMenuLinks.push(parent);
            });

            return restructuredMenuLinks.sort((a, b) => a.position - b.position);
        },
        navigateAction(pageName) {
            if (this.$route.name === pageName) {
                this.closeMyAccountMenu();
                return;
            }
            this.$router.push({ name: pageName });
        },
    },
};
</script>

<style lang="scss" scoped>
.my-account-container {
    min-height: 100%;
    padding: 12px;
    background: $light-grey-bg;
    .user-phone {
        margin: 0;
        background: $white-bg;
    }
    .account-menu {
        &-contents {
            color: $main-text;
            margin-top: 12px;
        }

        &-header {
            display: flex;
            padding-bottom: 8px;
            .close {
                margin-left: auto;
                svg {
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                }
            }
        }

        &-cta-box {
            border-radius: 4px;
            padding: 12px;
            background-color: white;
            ::v-deep {
                .user-phone {
                    padding: 0;
                    .account-menu-actions {
                        padding-top: 12px;
                    }
                    &-verification-status label {
                        @extend %small-details-font-400;
                    }
                }
                .user-phone-number {
                    line-height: 14px;
                }
                .user-phone-info {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        &-actions {
            display: flex;
            column-gap: 12px;
            align-items: center;
            .button-primary {
                border: 1px solid $button-primary-background-color;
            }
            .button {
                flex: 1;
                max-width: 100%;
                border-radius: 4px;
            }
            .button-accent-outline {
                background-color: $white-bg;
            }
        }
    }
}
</style>

<template>
    <div class="menu-categories">
        <Tabs
            :tabs="categoryTabs"
            :active="activeTab"
            :bottom-border="false"
            :top-border="false"
            class="category-tabs"
            tab-type="round"
            is-dark
            scrollable
            @select="setCurrentTab"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mutation, getter as generalGetter, action as storeAction } from '@/store/const';
import { Tabs } from '@/modules/core/components';

export default {
    name: 'LeftMenuCategories',
    components: { Tabs },
    data() {
        return {
            isOpenedDropdown: false,
        };
    },
    computed: {
        ...mapGetters({
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
            currentCategory: generalGetter.GET_CURRENT_CATEGORY,
            categories: generalGetter.GET_SORTED_CATEGORIES,
        }),
        icon() {
            return !this.isOpenedDropdown ? 'arrow_down' : 'icon-arrow-up';
        },
        categoryTabs() {
            return this.categories.map((category) => {
                return {
                    key: category.id,
                    iconId: this.$t(`project.sports.${category.id}.icon`),
                    iconClass: 'icon-size-medium',
                    text: category.name,
                };
            });
        },
        activeTab() {
            return this.categoryTabs.find((tab) => tab.key === this.currentCategoryId);
        },
    },
    methods: {
        setCurrentCategoryId(value) {
            this.$store.commit(mutation.SET_CURRENT_CATEGORY, value);
            this.trackMainNav();
        },
        trackMainNav() {
            this.$gtm.query({
                event: 'main_nav',
                click_text: 'change category',
            });
        },
        setCurrentTab(menuItem) {
            this.$store.commit(mutation.SET_CURRENT_CATEGORY, menuItem.key);
            this.$store.dispatch(storeAction.GET_CATEGORY_ACTIVE_REGIONS, menuItem.key);
            this.trackMainNav();
            this.$emit('updatedCategory', menuItem.key);
        },
        hideLeftMenu() {
            this.$emit('hideLeftMenu');
        },
    },
};
</script>

<style scoped lang="scss">
.menu-categories {
    display: flex;
    gap: 8px;
    background-color: $left-menu-category-color;
    padding: 8px;
    margin-top: 8px;
    overflow-x: scroll;
    scrollbar-width: none;

    .wrapper {
        .category {
            @extend %body-normal-font-400;
            padding: 15px 0;
            min-width: 128px;
            background-color: $left-menu-background-color;
            border: 1px solid $left-menu-item-border;
            color: $dark-grey-2;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            cursor: pointer;

            .icon {
                fill: #aaaeb0;
            }

            &.selected {
                font-weight: 500;
                background-color: $left-menu-category-selected-background;
                border: 1px solid $left-menu-category-selected-border;
                color: $green;

                .icon {
                    fill: $green;
                }
            }

            @include mq-xxs {
                padding: 15px 16px;
                min-width: auto;
            }
        }
    }

    .category-tabs {
        ::v-deep .tabs-menu .tabs-selector .tab-item {
            min-width: 128px;
        }
    }
}

.selector-container {
    background: $dark-menu-category-color;
    padding: 8px 12px;
    border-bottom: $border;
    min-height: 54px;

    > div:first-child {
        width: 100%;
    }

    .close {
        cursor: pointer;
        padding-left: 10px;

        svg {
            fill: $left-menu-svg-color;
            padding: 5px;
            width: 22px;
            height: 22px;
        }
    }
}
</style>

<template>
    <Deposit
        :show-select="showSelect"
        :polling="false"
        :button-text="buttonText"
        :input-label="inputLabel"
        :is-button-always-active="isButtonAlwaysActive"
        @closeClick="$emit('closeClick')"
    />
</template>
<script>
import { mapGetters } from 'vuex';

import { depositStatus, paystack } from '@/modules/payment';
import { action, mutation, getter } from '@/modules/payment/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';

import Deposit from './Deposit.vue';

export default {
    name: 'PaystackDeposit',
    components: { Deposit },
    props: ['showSelect', 'buttonText', 'inputLabel', 'isButtonAlwaysActive'],
    computed: {
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
        }),
        pollInProgress() {
            return this.$store.getters[getter.GET_POLL_IN_PROGRESS];
        },
        depositDetails() {
            return this.$store.getters[getter.GET_DEPOSIT_DETAILS];
        },
        selectedProvider() {
            return this.$store.getters[getter.GET_SELECTED_PROVIDER];
        },
        status() {
            return this.depositDetails.StatusType;
        },
        depositOptions() {
            return this.$store.getters[getter.GET_DEPOSIT_OPTIONS];
        },
        getAmountCents() {
            return this.depositOptions.Amount * 100;
        },
    },
    watch: {
        status: {
            deep: true,
            handler(statusType) {
                if (statusType === depositStatus.INIT && !this.pollInProgress) {
                    this.payWithPaystack();
                }
            },
        },
    },
    created() {
        paystack.loadScript();
    },
    methods: {
        payWithPaystack() {
            const { CustomerEmail, CustomerIdentifier, DepositInitId } = this.depositDetails;
            const { additionalData, channel } = this.selectedProvider;
            paystack.deposit(
                {
                    paystackkey: additionalData && additionalData.key,
                    email: CustomerEmail,
                    amount: this.getAmountCents,
                    ref: DepositInitId,
                    /**
                     * @deprecated channels are no longer used - now each method has a unique depositTypeName.
                     * can be removed if this is not a required field for the endpoint
                     */
                    channels: [channel],
                    customerId: CustomerIdentifier,
                },
                this.paystackCallback,
                this.paystackOnClose
            );
        },
        paystackCallback(response) {
            // TODO: to make paystack deposit id storable, for pending request in case page reload
            // add reservationaction and remove SET_POLL_IN_PROGRESS and POLL_DEPOSIT
            // this.$store.commit(mutation.RESERVE_DEPOSIT_ID, { type: this.type, DepositInitId: response.reference })
            this.$store.commit(mutation.SET_POLL_IN_PROGRESS, true);
            this.$store.dispatch(action.POLL_DEPOSIT, {
                DepositInitId: response.reference,
                providerName: this.NAME,
                Amount: this.depositOptions.Amount
            });
        },
        paystackOnClose() {
            this.$store.dispatch(action.RESET_DEPOSIT);
        },
    },
};
</script>

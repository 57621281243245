import { MQConstants } from '@/js/directives/media-query';

const SortConverterType = {
    NUMBER: 'number',
    STRING: 'string',
};
const sortConverter = {
    [SortConverterType.NUMBER]: (val) => Number(val),
    [SortConverterType.STRING]: (val) => String(val).toLocaleLowerCase(),
};

export const getCasinoGamesLimitForGridPerRow = (mediaQuery, isRightSideBarHidden, columnsByBreakpoints) => {
    const breakpointsPriority = [MQConstants.MD, MQConstants.SM, MQConstants.XS, MQConstants.XXS];
    let breakpointValues = { mdHiddenSidebar: 6, [MQConstants.MD]: 4, [MQConstants.SM]: 4, [MQConstants.XS]: 3, [MQConstants.XXS]: 3 };

    if (columnsByBreakpoints) {
        breakpointValues = {
            ...breakpointValues,
            ...columnsByBreakpoints,
        };
    }

    if (mediaQuery[MQConstants.MD] && isRightSideBarHidden) {
        return breakpointValues.mdHiddenSidebar;
    }
    let breakpoint = breakpointValues[breakpointsPriority[breakpointsPriority.length - 1]];
    breakpointsPriority.forEach((bp) => {
        if (mediaQuery[bp] && breakpointValues[bp] !== undefined) {
            breakpoint = breakpointValues[bp];
        }
    });
    return breakpoint;
};

const getSortGamesBy = (param, asc, type = SortConverterType.STRING) => {
    const ascValue = asc ? 1 : -1;
    return (a, b) => (sortConverter[type](a[param]) >= sortConverter[type](b[param]) ? ascValue : -ascValue);
};
export const getSortGamesByFavourites = (favourites, asc) => (a, b) =>
    asc ? favourites.indexOf(a.id) - favourites.indexOf(b.id) : favourites.indexOf(b.id) - favourites.indexOf(a.id);

export const sortGamesByPopular = getSortGamesBy('position', true, SortConverterType.NUMBER);

export const sortGamesByName = (asc) => getSortGamesBy('name', asc);

export const getGamesFilterFunction = (showFavourites, favourites, categoryId) => {
    let filterFn = () => true;
    if (showFavourites) {
        filterFn = ({ id }) => favourites.includes(id);
    } else if (categoryId) {
        filterFn = ({ categories }) => categories.includes(categoryId);
    }
    return filterFn;
};

export const fillGamesGapWithFakeGames = (games, gamesLimit) => {
    const newGames = [...games];
    for (let i = newGames.length; i < gamesLimit; i += 1) {
        newGames.push({
            id: `fake-${i}`,
            isFake: true,
            name: '',
        });
    }
    return newGames;
};

export const getColumnSortedGames = (games, rows) => {
    const numColumns = Math.ceil(games.length / rows);
    const sortedGames = [];
    let currentIndex = 0;
    for (let col = 0; col < numColumns; col++) {
        for (let row = 0; row < rows; row++) {
            const gridItemIndex = row * numColumns + col;
            if (gridItemIndex < games.length) {
                sortedGames[gridItemIndex] = games[currentIndex];
                currentIndex++;
            }
        }
    }
    return sortedGames;
};

<template>
    <div v-if="event" class="event">
        <LiveEvent v-if="isLive && event.id" :event="event" :market-id="marketId" />
        <EventHeader v-if="!isLive && event.id" :event="event" :market-id="marketId" />
        <div class="widget-wrapper">
            <slot name="widget" />
        </div>
        <div :class="{ 'tabs-container': true }">
            <Tabs
                v-if="isAllMarketsEnabled"
                :class="{
                    'event-tabs-event-ended': !hasMarkets || isEventEnded,
                    'event-tabs-with-markets': hasMarkets,
                    'event-tabs-top-border': isStatisticOpen,
                }"
                class="event-tabs"
                :tabs="marketTabs"
                :active="currentTab"
                :top-border="false"
                bottom-border
                tab-type="square"
                scrollable
                is-dark
                tab-change-tracking-name="event_tabs_click_"
                @select="setCurrentTab"
            />
        </div>
        <div v-if="isEventEnded" class="event-ended">
            <h4>{{ $t('ui.eventPage.eventEnded.title') }}</h4>
            <p>{{ $t('ui.eventPage.eventEnded.body') }}</p>
            <router-link :to="{ path: '/upcoming' }" class="button button-primary">
                {{ $t('ui.eventPage.eventEnded.browseEvents') }}
            </router-link>
            <div class="event-ended-links">
                <router-link :to="{ path: '/help' }" class="underline event-ended-link">
                    {{ $t('ui.eventPage.eventEnded.help') }}
                </router-link>
            </div>
        </div>
        <ErrorPage
            v-else-if="!hasMarketsByFilter && showMarkets"
            :title="errorMessage.title"
            :section-list="errorMessage.description ? [errorMessage.description] : []"
        >
            <template slot="header">
                <img v-if="imgSrc" class="error-image" :src="imgSrc" />
            </template>
            <template slot="footer">
                <router-link v-if="!hasMarkets" class="underline error-link bold" to="/upcoming">
                    {{ $t(`ui.eventPage.eventEnded.browseUpcomingEvents`) }}
                </router-link>
                <router-link v-else class="button button-primary error-button" :to="{ path: $route.path, query: { filter: 'all' } }">
                    {{ $t(`ui.common.viewAll`) }}
                </router-link>
            </template>
        </ErrorPage>
        <template v-else>
            <div v-for="(marketGroup, index) in marketsByTab[currentTab.key]" :key="'market-group-' + index" class="events-container"
            :data-test-id="`market-${marketGroup[0].marketType.id}`">
                <Modal
                    :name="`market-group-info-${marketGroup[0].marketType.id}`"
                    width="80%"
                    :options="{ position: { top: '26px' } }"
                    :avoid-collision-with-footer="true"
                    @close="$modal.hide(`market-group-info-${marketGroup[0].marketType.id}`)"
                >
                    {{ marketGroup[0].marketType.explainer }}
                </Modal>
                <Modal :name="twoUpModalId" width="100%" @close="$modal.hide(twoUpModalId)">
                    <renderer :input="$t('ui.iconTooltips.twoUp')" />
                </Modal>
                <Modal :name="boostedModalId" width="100%" @close="$modal.hide(boostedModalId)">
                    {{ $t('ui.iconTooltips.boosted') }}
                </Modal>
                <div class="events-sub-container">
                    <div class="events-sub-header">
                        <div class="event-title" data-test-id="title">
                            <h4>{{ setMarketTitle(marketGroup[0]) }}</h4>
                            <span
                                v-if="!!marketGroup[0].marketType.explainer"
                                class="event-info"
                                :data-test-id="'marketInfo-' + marketGroup[0].marketType.id"
                                @click="openModal(event, marketGroup)"
                            >
                                <SvgIcon class="event-info-icon" icon-id="icon-info" />
                            </span>
                        </div>
                        <div class="icons-right">
                            <Badge
                                v-if="isTwoUpIconVisible(marketGroup[0])"
                                class="best-odds-holder pointer"
                                type="best-odds"
                                icon-name="icon-two-up"
                                @click.native="$modal.show(twoUpModalId)"
                                data-test-id="twoUpBadge"

                            />
                            <Badge
                                v-if="isBoosted(marketGroup[0])"
                                class="best-odds-holder pointer"
                                type="best-odds"
                                :icon-name="bestOddsIcon"
                                :text="bestOddsText"
                                :icon-tooltip="$t('project.sports.boosted.text')"
                                @click.native="$modal.show(boostedModalId)"
                                data-test-id="bestOddsBadge"
                            />
                        </div>
                    </div>
                    <BetlineList
                        :event="event"
                        :markets="showMarkets ? marketGroup : []"
                        :show-market-count="!showMarkets"
                        :available-open-betslip="true"
                    />
                </div>
            </div>
        </template>
        <HotDescription v-if="containsHot && isAllMarketsEnabled" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { marketTypeCategory, filterCategory, EXCLUDED_MARKET_NAMES, WS_FILTERS_TEMPLATE } from '@/modules/sport';
import { mutation as sportMutation } from '@/modules/sport/store/const';
import { helper } from '@/modules/core';
import { getter as generalGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { Tabs } from '@/modules/core/components';
import Badge from '@/components/Badge.vue';
import HotDescription from '@/components/HotDescription.vue';
import BetlineList from './BetlineList.vue';
import LiveEvent from './LiveEvent.vue';
import EventHeader from './EventHeader.vue';
import ErrorPage from '@/components/Pages/ErrorPage.vue';
import { ERROR_IMAGES } from '@/components/content/content-const';
import { routeName } from '@/router/const-name';

export default {
    name: 'Event',
    components: { LiveEvent, BetlineList, HotDescription, Tabs, EventHeader, ErrorPage, Badge },
    props: {
        event: {
            type: Object,
        },
        marketId: [String, Number],
    },
    data() {
        return {
            currentFilter: filterCategory.ALL,
            socketCancel: null,
            isSocketChannelRequested: false,
            filterCategory,
        };
    },
    computed: {
        ...mapGetters({
            dateOptions: generalGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            settings: generalGetter.GET_SETTINGS,
            bestOddsLabel: generalGetter.GET_BEST_ODDS_LABEL,
            isEventPageSocketsEnabled: generalGetter.IS_EVENT_PAGE_SOCKETS_ENABLED,
            marketTabsConfiguration: generalGetter.GET_SPORTSBOOK_TABS_CONFIGURATION,
        }),
        ...mapState({
            isStatisticOpen: (state) => state.sport.isStatisticOpen,
            errors: (state) => state.sport.sports.singleEvent.errors,
            containsHot: (state) => state.sport.sports.containsHot,
        }),
        sportType() {
            return Number(this.event?.category.id);
        },
        showMarkets() {
            return this.marketId !== marketTypeCategory.NONE;
        },
        isAllMarketsEnabled() {
            return this.marketId === marketTypeCategory.ALL;
        },
        marketTabs() {
            return this.marketTabsConfiguration
                .reduce((acc, tab) => {
                    const tabSpecs = tab.categorySpecs.find(({ id }) => id === String(this.sportType));

                    if (!tabSpecs) {
                        return acc;
                    }

                    const tabHasMarkets = this.allEventMarketsIds.includes(tab.id);
                    const formattedTab = {
                        key: tab.id,
                        ...(filterCategory.ALL === tab.id ? {} : {
                            count: this.formattedTabCounter(helper.getObjectField(this.marketsByTab, `${tab.id}.length`, 0))
                        }),
                        text: this.$t(`ui.eventPage.category.${tab.id}`, { indefinite: true }) || tab.name,
                        priority: tabSpecs.priority,
                    };

                    return tabSpecs.allowEmpty || tabHasMarkets ? [...acc, formattedTab] : acc;
                }, [])
                .sort((a, b) => a.priority - b.priority);
        },
        currentTab() {
            return {
                key: this.$route.query.filter || filterCategory.ALL,
            };
        },
        marketsByTab() {
            const { markets } = this.event;
            const sortedMarkets = markets.flat().sort(([marketA], [marketB]) => marketA.marketType.priority - marketB.marketType.priority);
            return sortedMarkets.reduce((acc, marketList) => {
                const [ market ] = marketList;
                market.marketType.tabs.forEach((tab) => {
                    if (!acc[tab]) {
                        acc[tab] = [];
                    }
                    acc[tab].push(marketList);
                });
                return acc;
            }, {});
        },
        errorMessage() {
            const allMarkets = this.$t('ui.eventPage.error.description.allMarketsNotAvailable');
            const filteredMarkets = this.isLive
                ? this.$t('ui.eventPage.error.description.noLiveActiveMarkets')
                : this.$t('ui.eventPage.error.description.noActiveMarkets');
            return {
                title: this.$t('ui.eventPage.error.title.noActiveMarkets'),
                description: this.hasMarkets ? filteredMarkets : allMarkets,
            };
        },
        imgSrc() {
            return ERROR_IMAGES.errorNoActiveMarkets;
        },
        hasMarketsByFilter() {
            return !!this.marketsByTab[this.currentTab.key]?.length;
        },
        hasMarkets() {
            const { markets } = this.event || {};
            return !!markets?.length;
        },
        isLive() {
            return this.event?.additionalInfo?.live;
        },
        isEventEnded() {
            const starts = new Date(this.event?.startTime).getTime();
            const now = new Date().getTime();
            return this.event?.startTime && now > starts && !this.isLive;
        },
        brandIdentifier() {
            return this.settings?.brand?.identifier;
        },
        socketFilter() {
            return this.isLive && this.brandIdentifier && this.event?.id
                ? [WS_FILTERS_TEMPLATE.odds, WS_FILTERS_TEMPLATE.scoreboard].map((template) =>
                      this.$interpolate(template, { brandIdentifier: this.brandIdentifier, eventId: this.event.id })
                  )
                : [];
        },
        bestOddsText() {
            return this.bestOddsLabel;
        },
        bestOddsIcon() {
            return this.bestOddsText ? 'icon-best-odds' : 'icon-best-odds-circled';
        },
        allEventMarketsIds() {
            if (!this.event) {
                return [];
            }

            const allMarkets = this.event.markets.flatMap((market) => market[0].flatMap(({ marketType }) => marketType.tabs));

            return helper.removeArrayDuplicates(allMarkets);
        },
        twoUpModalId() {
            return `event-two-up-${this.event.id}`;
        },
        boostedModalId() {
            return `event-boosted-${this.event.id}`;
        },
    },
    watch: {
        '$route.query.filter': {
            immediate: true,
            handler(value) {
                if (value) {
                    this.currentFilter = value;
                }
            },
        },
        socketFilter: {
            immediate: true,
            handler(filters) {
                if (!this.$isEmpty(filters) && this.isEventPageSocketsEnabled && !this.isSocketChannelRequested) {
                    this.$rs.rsSocket.then(({ socket }) => {
                        this.$rs
                            .requestChannel(
                                socket,
                                `live_event_${this.event.id}`,
                                ({ data }) => {
                                    const { value } = data ? JSON.parse(data) : {};
                                    if (!value) return;
                                    if (value.scoreboardResponse) this.$store.commit(sportMutation.UPDATE_SINGLE_EVENT_SCOREBOARD, value);
                                    if (value.odds) this.$store.commit(sportMutation.UPDATE_SINGLE_EVENT_ODDS, value);
                                },
                                {
                                    data: { filters },
                                    metadata: 'data',
                                }
                            )
                            .then((cancel) => {
                                // call cancel() to unsubscribe the channel
                                this.socketCancel = cancel;
                                this.isSocketChannelRequested = true;
                            });
                    });
                }
            },
        },
    },
    created() {
        const isEmptyOrIncorrectFilterQuery = !this.$route.query.filter;
        if (isEmptyOrIncorrectFilterQuery) {
            this.setCurrentTab({ key: filterCategory.ALL });
        }
    },
    beforeDestroy() {
        if (this.socketCancel) {
            this.socketCancel();
        }
    },
    methods: {
        $isEmpty: helper.isEmpty,
        $interpolate: helper.interpolate,
        isTwoUpIconVisible({ additionalInfo }) {
            return helper.getObjectField(additionalInfo, 'twoUp', false);
        },
        isBoosted({ additionalInfo }) {
            return helper.getObjectField(additionalInfo, 'boosted', false);
        },
        openModal(event, marketGroup) {
            if (!event.id || !marketGroup.length) {
                return;
            }
            this.$gtm.query({
                event: 'info_icon_click',
                sport: event.sport,
                market: event.marketName,
                product: `sports-book-${this.isLive ? 'live' : 'upcoming'}`,
                isAuthenticated: this.isAuthenticated,
            });
            this.$modal.show(`market-group-info-${marketGroup[0].marketType.id}`);
        },
        setCurrentTab({ key }) {
            const currentQueryParams = { ...this.$route.query };
            const { filter } = currentQueryParams;
            if (filter !== key) {
                const routeConfig = {
                    query: {
                        ...this.$route.query,
                        filter: key,
                    },
                };

                if (this.$route.name === routeName.GENERIC_PAGE) {
                    routeConfig.path = this.$route.path;
                } else {
                    routeConfig.name = this.$route.name;
                }

                this.$router.replace(routeConfig);
            }
        },
        errorButtonClick() {
            this.setCurrentTab({ key: filterCategory.ALL });
        },
        formulateMarketTitle(groupName, groupedName) {
            if (groupName === groupedName) return groupName;

            const modifiedGroupName = EXCLUDED_MARKET_NAMES.includes(groupName) ? '' : groupName;
            const modifiedGroupedName = groupedName.replace(' - ', ' | ');
            const separator = modifiedGroupName ? ' | ' : '';

            return `${modifiedGroupName}${separator}${modifiedGroupedName}`;
        },
        setMarketTitle(market) {
            return helper.formulateMarketTitle({
                displayName: market.marketType.displayName,
                marketName: market.marketType.name,
                eventName: this.event.name,
                additionalText: this.isTwoUpIconVisible(market) ? this.$t('ui.event.market.twoUp') : '',
            });
        },
        formattedTabCounter(count) {
            return count > 99 ? '99+' : count;
        },
    },
};
</script>

<style scoped lang="scss">
.event {
    position: relative;

    .widget-wrapper {
        border-top: $event-header-border-width solid $event-header-border-color-mobile;
        line-height: 0;
    }

    &-ended {
        padding: 64px 50px 70px;
        text-align: center;

        h4 {
            font-size: 18px;
            margin-bottom: 8px;
        }

        p {
            font-size: 14px;
            margin-bottom: 16px;
        }

        &-links {
            padding-top: 24px;

            .event-ended-link {
                display: inline-block;
                margin: 0 12px;
            }
        }
    }

    .event-tabs {
        margin-bottom: 16px;

        &-with-markets {
            margin-bottom: 0;
        }

        &-event-ended {
            pointer-events: none;
            cursor: default;
            overflow: hidden;

            ::v-deep {
                .tabs-menu {
                    .tabs-selector .tab-text,
                    .tabs-selector.active {
                        color: $dark-grey-6;
                        border-bottom: 1px solid transparent;
                    }
                }
            }
        }
    }

    h3 {
        @extend %h4-font-700;
        text-transform: uppercase;
        padding: 8px 12px 0 12px;
        margin: 0;
    }

    .events {
        &-container {
            position: relative;
        }

        &-sub-container {
            padding: 16px 12px 8px 12px;
        }

        &-sub-header {
            display: flex;
            margin-bottom: 8px;
            align-items: center;
            justify-content: space-between;

            h4 {
                @extend %body-normal-font-700;
                color: $grey-text;
                margin-bottom: 0;
            }

            .icons-right {
                display: flex;
                align-items: center;
                margin: 0 2px 0 auto;
            }

            .best-odds-holder {
                margin-left: 8px;
            }
        }
    }

    &-title {
        display: flex;
        align-items: center;
        flex: 1;
    }

    &-info {
        margin-left: 8px;
        line-height: 0;
        cursor: pointer;

        svg.event-info-icon {
            width: 16px;
            height: 16px;
            fill: $dark-green;
        }
    }

    .error-image {
        @include mq-xxs {
            width: 100px;
        }
    }
}
</style>

import * as Sentry from '@sentry/vue';
import router from '@/router';

const DEFAULT_CONFIG = {
    enabled: true,
    debug: false,
};

function initSentry(Vue, config) {
    // `errorHandler` should be defined before Sentry.init() - see GitHub Issue: https://github.com/vuejs/vue/issues/8433
    Vue.config.errorHandler = (err, vm, info) => {
        if (config.environment !== 'production') {
            Vue.util.warn(`Error in ${info}: "${err.toString()}"`, vm);
        }
        console.error(err);
    };
    Vue.prototype.$sentry = Vue.$sentry = Sentry;
    Vue.$sentry.init({
        ...config,
        Vue,
        integrations: [Sentry.breadcrumbsIntegration({ dom: false }), Sentry.browserTracingIntegration({ router })],
        beforeSend(event, hint) {
            if (!event.exception) {
                return null;
            }
            const frames = event.exception.values[0].stacktrace.frames;
            const isInternalError = frames.some((frame) => frame.filename && frame.filename.includes(window.location.origin));
            if (!isInternalError) {
                return null;
            }
            const originalThrownObject = hint.originalException;
            if (hint && originalThrownObject instanceof Event) {
                Sentry.withScope((scope) => {
                    const { currentTarget, isTrusted, target = {}, type } = originalThrownObject;
                    scope.setExtras({ currentTarget, isTrusted, target, type });
                    Sentry.captureException(new Error(`${target.src || 'Unknown '} Event Error`));
                });
                return null;
            }
            return event;
        },
    });
    Vue.$sentry.setTag('platform_type', window.isBridgeEnabled ? 'android' : 'web');
}

export default function install(Vue, config = DEFAULT_CONFIG) {
    try {
        initSentry(Vue, { ...DEFAULT_CONFIG, ...config });
    } catch (error) {
        Vue.util.warn(`Error in [vue-sentry]: ${error}`);
    }
}

export const action = {
    CALL_ME_REQUEST: 'platform/callMeRequest',
    CHANGE_SITE_LANGUAGE: 'platform/changeSiteLanguage',
    CHECK_JURISDICTION: 'platform/checkJurisdiction',
    CHIPS_BALANCE_EXCHANGE: 'platform/chipsBalanceExchange',
    FETCH_REGIONS: 'platform/fetchRegions',
    GET_ANNOUNCEMENT: 'platform/getAnnouncement',
    GET_CALL_ME_DATA: 'platform/getCallMeData',
    GET_CAMPAIGN_ELIGIBILITY: 'platform/getCampaignEligibility',
    SET_USING_CAMPAIGN_ELIGIBILITY: 'platform/setUsingCampaignEligibility',
    GET_CASHIN_TAX_PREVIEW: 'platform/getCashInTaxPreview',
    GET_CONFIG: 'platform/getConfig',
    SET_CONFIG: 'platform/setConfig',
    GET_CONTENT: 'platform/getContent',
    GET_CONTENT_V2: 'platform/getContentV2',
    GET_DERIVED_DATA: 'platform/getDerivedData',
    GET_MODAL_WINDOW: 'platform/getModalWindow',
    GET_PAGE: 'platform/getPage',
    GET_PAGE_PREVIEW: 'platform/getPagePreview',
    GET_PAGE_V2: 'platform/getPageV2',
    GET_STATEMENT: 'platform/getStatement',
    GET_TEXT: 'platform/getText',
    LOAD_AGI_SETTINGS: 'platform/loadAgiSettings',
    GET_COMPONENT_DATA: 'platform/getComponentData',
    PAWAPASS_VERIFICATION: 'platform/pawapassVerification',
    PUT_DERIVED_DATA: 'platform/putDerivedData',
    REFRESH_CONTENT: 'platform/refreshContent',
    RESET_STATEMENT: 'platform/resetStatement',
    SET_AFFILIATE_ID: 'platform/setAffiliateId',
    SET_NEW_BADGE_COUNT: 'platform/putNewBadgeCount',
    TRACK_USER_DATA: 'platform/trackUserData',
    TRACK_USER_PRESENCE: 'platform/trackUserPresence',
    GET_CHIPS_TOP_UP_TAX_PREVIEW: 'platform/getTopUpTaxPreview',
    GET_CHIPS_CASH_IN_TAX_PREVIEW: 'platform/getCashInTaxPreview',
    UPDATE_LANGUAGE_PREFERENCE: 'platform/updateLanguagePreference',
    POLL_ACTIVE_PROGRESSIVE_JACKPOTS: 'platform/pollActiveProgressiveJackpots',
    GET_ACTIVE_PROGRESSIVE_JACKPOTS: 'platform/getActiveProgressiveJackpots',
    GET_PROGRESSIVE_JACKPOT_WINNERS: 'platform/getProgressiveJackpotWinners',
    GET_ALL_ACTIVE_JURISDICTIONS: 'platform/getAllActiveJurisdictions',
};

export const mutation = {
    SET_BRAND_PREFERENCE: 'platform/setBrandPreference',
    SET_CONTENT_SLOTS: 'platform/setContentSlots',
    SET_PAGE: 'platform/setPage',
    SET_CONTENT_TEXT: 'platform/setContentText',
    SET_CONTENT_ERROR: 'platform/setContentError',
    REMOVE_CONTENT_ERROR: 'platform/removeContentError',
    SET_STATEMENT_ERROR: 'platform/setStatementError',
    ACTIVATE_GTM: 'platform/activateGTM',
    APPEND_AGI_SETTINGS: 'platform/appendAgiSettings',
    RESET_CHIPS_ERROR: 'platform/resetChipsError',
    RESET_KYC: 'platform/resetKycStatus',
    RESET_STATEMENT: 'platform/resetStatement',
    SET_AFFILIATE_ID: 'platform/setAffiliateId',
    SET_AGI_SETTINGS: 'platform/setAgiSettings',
    SET_SETTINGS_ERROR: 'platform/setSettingsError',
    SET_ALL_JURISDICTIONS: 'platform/setAllJurisdictions',
    SET_ANNOUNCEMENT: 'platform/setAnnouncement',
    SET_CALL_ME_DATA: 'platform/setCallMeData',
    SET_CALL_ME_STATUS: 'platform/setCallMeStatus',
    SET_CAMPAIGN_ELIGIBILITY: 'platform/setCampaignEligibility',
    SET_USING_CAMPAIGN_ELIGIBILITY: 'platform/setUsingCampaignEligibility',
    SET_CHIPS_ERROR: 'platform/setChipsError',
    SET_CHIPS_CASH_IN_TAX_DETAILS: 'platform/setChipsCashInTaxDetails',
    SET_CHIPS_TOP_UP_TAX_DETAILS: 'platform/setChipsTopUpTaxDetails',
    SET_CONFIG: 'platform/setConfig',
    SET_COUNTRY_CODE_IS: 'platform/setCountryCodeIs',
    SET_FAVORITE_CASINO_GAME: 'platform/setFavoriteCasinoGame',
    SET_FORCE_GET_CONTENT: 'platform/setForceGetContent',
    SET_IS_NEED_TO_SEND_PAYMENT_COMPONENT_VISIBILITY_EVENT: 'platform/setIsNeedToSendPaymentComponentVisibilityEvent',
    SET_IS_PAYMENT_COMPONENT_VISIBILITY_RULE_PASSED: 'platform/setIsPaymentComponentVisibilityRulePassed',
    SET_KYC: 'platform/setKycStatus',
    SET_NEW_BADGE_COUNT: 'platform/setNewBadgeCount',
    SET_REGIONS: 'platform/setRegions',
    SET_STATEMENT: 'platform/setStatement',
    SET_SURVEY: 'platform/setSurvey',
    SET_USER_STATE: 'platform/setUserState',
    UPDATE_PREFERENCE: 'platform/updatePreference',
    SET_ACTIVE_PROGRESSIVE_JACKPOTS: 'platform/setActiveProgressiveJackpots',
    SET_ACTIVE_PROGRESSIVE_JACKPOT_POLLING: 'platform/setActiveProgressiveJackpotPolling',
    CLEAR_ACTIVE_PROGRESSIVE_JACKPOT_POLLING: 'platform/clearActiveProgressiveJackpotPolling',
    SET_PROGRESSIVE_JACKPOT_WINNERS: 'platform/setProgressiveJackpotWinners',
    SET_LAST_SEEN_WIN_BET_ID: 'platform/setLastSeenWinBetId',
    SET_ACTIVE_JURISDICTIONS: 'platform/setActiveJurisdictions',
};

export const getter = {
    GET_BEST_ODDS_LABEL: 'platform/getBestOddsLabel',
    GET_BRAND: 'platform/getBrand',
    GET_BRAND_DETAILS: 'platform/getBrandDetails',
    GET_BRAND_PREFERENCE: 'platform/getBrandPreference',
    GET_BIG_WIN: 'platform/getBigWin',
    GET_CAMPAIGN_ELIGIBILITY: 'platform/getCampaignEligibility',
    GET_IS_USING_CAMPAIGN_ELIGIBILITY: 'platform/getIsUsingCampaignEligibility',
    GET_CONTENT: 'platform/getContent',
    GET_CONTENT_SLOTS: 'platform/getContentSlots',
    GET_COUNTRY: 'platform/getCountry',
    COUNTRY_CODE_IS: 'platform/countryCodeIs',
    GET_CURRENT_USER_STATUS: 'platform/getCurrentUserStatus',
    GET_DATE_OPTIONS: 'platform/getDateOptions',
    GET_DEPOSIT_OPTIONS_CONFIG: 'platform/getDepositOptionsConfig',
    GET_DEPOSIT_OPTION_BY_PHONE_OPERATOR: 'platform/getDepositOptionByPhoneOperator',
    GET_DEPOSIT_OPTION_BY_PREFERRED_PAYMENT_PROVIDER: 'platform/getDepositOptionByPreferredPaymentProvider',
    GET_DEPOSIT_PAGE_LINK: 'platform/getDepositPageLink',
    GET_IS_NEED_TO_SEND_PAYMENT_COMPONENT_VISIBILITY_EVENT: 'platform/getIsNeedToSendPaymentComponentVisibilityEvent',
    GET_IS_PAYMENT_COMPONENT_VISIBILITY_RULE_PASSED: 'platform/getIsPaymentComponentVisibilityRulePassed',
    GET_MINIMUM_ODDS_FOR_BONUS: 'platform/getMinimumOddsForBonus',
    GET_PAGE: 'platform/getPage',
    GET_PAGES: 'platform/getPages',
    GET_POOL_TAX_AMOUNT: 'platform/getPoolTaxAmount',
    GET_PREFERENCE: 'platform/getPreference',
    GET_SETTINGS: 'platform/getSettings',
    GET_SOCKETS_URL: 'platform/getSocketsUrl',
    GET_STAKE_TAX_AMOUNT: 'platform/getStakeTaxAmount',
    GET_SURVEY: 'platform/getSurvey',
    GET_TAX_AMOUNT: 'platform/getTaxAmount',
    GET_TAX_COMPENSATE_RATE: 'platform/getTaxCompensate',
    GET_VIRTUAL_TAX_COMPENSATE_RATE: 'platform/getVirtualTaxCompensate',
    GET_TEXT: 'platform/getText',
    GET_USER_SETTINGS: 'platform/getUserSettings',
    GET_VIRTUAL_STAKE_TAX_AMOUNT: 'platform/getVirtualStakeTaxAmount',
    GET_VIRTUAL_TAX_AMOUNT: 'platform/getVirtualTaxAmount',
    GET_CASH_IN_TAX_DETAILS: 'platform/getCashInTaxDetails',
    GET_TOP_UP_TAX_DETAILS: 'platform/getTopUpTaxDetails',
    IS_AUTO_CASHOUT_ENABLED: 'platform/isAutoCashOutEnabled',
    GET_JURISDICTIONS: 'platform/getJurisdictions',
    IS_BONUS_SCHEMA: 'platform/isBonusSchema',
    IS_EVENT_PAGE_SOCKETS_ENABLED: 'platform/isEventPageSocketsEnabled',
    IS_FORCE_GET_CONTENT: 'platform/isForceGetContent',
    IS_KYC_VERIFIED_OR_BYPASS: 'platform/isKycVerifiedOrBypass',
    IS_MULTIPLIER_SCHEMA: 'platform/isMultiplierSchema',
    IS_OVERTHRESHOLD: 'platform/isOverThreshold',
    IS_POOL_TAX_ENABLED: 'platform/isPoolTaxEnabled',
    IS_TAX_ENABLED: 'platform/isTaxEnabled',
    IS_VIRTUAL_ENABLED: 'platform/isVirtualEnabled',
    IS_VIRTUAL_TAX_ENABLED: 'platform/isVirtualTaxEnabled',
    IS_VIRTUAL_WIN_TAX_ENABLED: 'platform/isVirtualWinTaxEnabled',
    IS_WIN_TAX_ENABLED: 'platform/isWinTaxEnabled',
    GET_CATEGORIES: 'platform/getCategories',
    GET_SPORTSBOOK_TABS_CONFIGURATION: 'platform/getSportsbookTabsConfiguration',
    GET_JURISDICTION_CONFIG: 'platform/getJurisdictionConfig',
    IS_CASHOUT_AVAILABLE: 'platform/isCashOutAvailable',
    GET_CASHOUT_AVAILABILITY_TIME: 'platform/getCashOutAvailabilityTime',
    GET_MARKET_TYPE_FILTERS: 'platform/getMarketTypeFilters',
    GET_PROGRESSIVE_JP: 'platform/getProgressiveJP',
    GET_CASINO_TAX_AMOUNT: 'platform/getCasinoTaxAmount',
    GET_SPORT_PROGRESSIVE_JP_FROM_SETTINGS: 'platform/getSportProgressiveJpFromSettings',
    GET_LAST_WIN_INFO: 'platform/getLastWinInfo',
    GET_LAST_SEEN_WIN_BET_ID: 'platform/getLastSeenWinBetId',
    IS_BIG_WIN_NOTIFICATION_DISABLED: 'platform/isBigWinNotificationDisabled',
    GET_AGE_LIMIT_REGULATION_CONTENT: 'platform/getAgeLimitRegulationInfo',
    GET_PHONE_NUMBER_VALIDATION: 'platform/getPhoneNumberValidation',
    GET_SIDE_BAR_DISABLED_PAGES: 'platform/getSideBarDisabledPages',
    GET_BEST_ODDS: 'platform/getBestOdds',
    IS_BOOSTED_ICON_ENABLED: 'platform/isBoostedIconEnabled',
    IS_LIVE_EVENT_BEST_ODDS_ICON_ENABLED: 'platform/isLiveEventBestOddsIconEnabled',
    HAS_NEW_FAVORITE_GAMES_FIELD: 'platform/hasNewFavoriteGamesField',
    GET_ACTIVE_BADGES: 'platform/getActiveBadges',
    GET_SPLASH_PAGE_CONFIGURATION: 'platform/getSplashPageConfiguration',
    IS_SPLASH_PAGE_ENABLED: 'platform/isSplashPageEnabled',
    IS_LANDING_PAGE: 'platform/isLandingPage',
    GET_ACTIVE_JURISDICTIONS: 'platform/getActiveJurisdictions',
    GET_OTP_OPTIONS: 'platform/getOtpOptions',
    GET_PHONE_COUNTRY_CODE: 'platform/getPhoneCountryCode',
    GET_PHONE_PREFIX: 'platform/getPhonePrefix',
    GET_PHONE_LENGTH: 'platform/getPhoneLength',
};

export const mutation = {
    ADD_NOTIFICATION: 'addNotification',
    CLEAR_NOTIFICATIONS: 'removeNotifications',
    CLOSE_ACCOUNT_MENU: 'closeAccountMenu',
    SET_COUNTRIES: 'setCountries',
    SET_CURRENT_CATEGORY: 'setCurrentCategory',
    SET_TOURNAMENTS: 'setTournaments',
    TOGGLE_LEFT_MENU_COUNTRY: 'toggleLeftMenuCountry',
    TOGGLE_LEFT_MENU_OTHER: 'toggleLeftMenuOther',
    TOGGLE_NOTIFICATION: 'toggleNotification',
    UPDATE_TERMS_DECLINE_STATUS: 'updateTermsDeclineStatus',
    TOGGLE_BETSLIP_STATE: 'toggleBetslipState',
    SET_CASINO_FAVORITES: 'setCasinoFavorites',
    SET_CASINO_LAYOUT: 'setCasinoLayout',
    SET_GAMES_COLLECTION: 'setGamesCollection',
    SET_GAMES_SORT_BY: 'setGamesSortBy',
    SET_GAME_CASINO_LOAD_ERROR: 'setGameCasinoLoadError',
    SET_GAME_LAUNCH_ERROR: 'setGameLaunchError',
    SET_TURNSTILE: 'ui/setTurnstile',
};

export const action = {
    ACCEPT_TERMS: 'platform/AcceptTerms',
    GET_CATEGORIES_LIST: 'getCategoriesList',
    GET_CATEGORY_ACTIVE_REGIONS: 'getCategoryActiveRegions',
    LOADING: 'loading',
    NOTIFY: 'Notify',
    SET_BETSLIP_STATE: 'setBetslipState',
    SET_SIDEBAR_STATE: 'setSidebarState',
    TOGGLE_SIDEBAR: 'toggleSidebar',
    TOGGLE_BETSLIP_STATE: 'toggleBetslipState',
    ADD_FAVORITE_GAME: 'addFavoriteGame',
    GET_CASINO_GAMES: 'getCasinoGames',
    OPEN_CASINO_GAME: 'openCasinoGame',
    SET_TURNSTILE: 'ui/setTurnstile',
};

export const getter = {
    GET_SORTED_CATEGORIES: 'getSortedCategories',
    GET_CHIPS: 'getChips',
    GET_CURRENT_CATEGORY: 'getCurrentCategory',
    GET_CURRENT_CATEGORY_ID: 'getCurrentCategoryId',
    GET_JACKPOT_POOL: 'getJackpotPool',
    GET_MENU_ITEM_BY_BETSLIP: 'getMenuItemByBetslip',
    GET_MY_BETS_MENU: 'getMyBetsMenu',
    GET_NOTIFICATION_TYPES: 'getNotificationTypes',
    GET_QUICK_MAIN_MENU: 'getQuickMainMenu',
    GET_UPCOMING_VIRTUAL_ROUND_INDEX: 'getUpcomingVirtualRoundIndex',
    IS_VIRTUAL_SPORT: 'isVirtualSport',
    GET_FAVORITE_GAMES: 'getFavoriteGames',
    GET_FAVORITE_GAMES_IDS: 'getFavoriteGamesIds',
    GET_GAME_CATEGORY_BY_NAMES: 'getGameCategoryByNames',
    GET_GAME_COLLECTION: 'getGameCollection',
    GET_GAME_COLLECTION_BY_CATEGORIES: 'getGameCollectionByCategories',
};

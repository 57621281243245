<template>
    <div v-if="country && phonePrefix" class="country-code-container">
        <InputField
            class="phone-number-input"
            :name="name"
            type="tel"
            :form-name="formName"
            :value="value"
            :help-text="phoneHelpText"
            :v="v"
            :attrs="{ ...$attrs }"
            :error-messages="errors"
            @value="handleInputChange"
        >
            <template slot="label">{{ label || $t('ui.common.user.phoneNumber') }}</template>
            <template slot="optional">
                <slot name="optional" />
            </template>
            <CountryFlag :iso-code="country.toUpperCase()" class="flag" />
            <span class="country-code">+{{ phonePrefix }}</span>
        </InputField>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { InputField } from '@/modules/core/components';
import { getter as platformGetter } from '@/modules/platform/store/const';
import CountryFlag from '@/modules/platform/components/CountryFlag.vue';

export default {
    name: 'PhoneNumber',
    components: { CountryFlag, InputField },
    props: {
        label: {
            type: String,
        },
        value: {
            type: String,
            default: '',
        },
        formName: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            default: 'phoneNumber',
        },
        validator: {
            type: Function,
            default: () => true,
        },
        helpText: {
            type: String,
        },
        error: {
            type: String,
        },
        v: {
            type: Object,
        },
        errorMessages: {
            type: Object,
        },
    },
    computed: {
        ...mapGetters({
            country: platformGetter.GET_COUNTRY,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            phonePrefix: platformGetter.GET_PHONE_PREFIX,
            phoneLength: platformGetter.GET_PHONE_LENGTH,
        }),
        errors() {
            const defaultErrors = {
                required: this.$t('ui.common.form.error.phoneNumberRequired'),
            };
            return { ...defaultErrors, ...this.errorMessages };
        },
        phoneHelpText() {
            if (this.helpText) return this.helpText;

            const phoneNumberExample = this.brandPreference.phoneNumberExample;

            if (phoneNumberExample) {
                return this.$t('ui.common.phoneNumber.helpText', { phoneNumberExample });
            }

            return '';
        },
    },
    methods: {
        handleInputChange(value) {
            // Keep only numeric digits, removing all non-numeric characters
            const numericValue = value.replace(/\D/g, '');

            // If a phone number length restriction exists and the limit is exceeded, stop execution
            if (this.phoneLength && numericValue.length > this.phoneLength) return;

            this.$emit('value', numericValue);
        },
    },
};
</script>

<style scoped lang="scss">
.country-code-container {
    width: 100%;
}
.phone-number-input {
    width: 100%;

    ::v-deep .input-field-wrapper {
        background-color: $input-wrapper-background-color;
        border-radius: 2px 5px 5px 2px;
        align-items: center;
        width: 100%;
    }

    ::v-deep input {
        padding-left: 40px;

        [disabled] & {
            color: $grey-text;
            font-weight: bold;
        }
    }

    .flag {
        flex: 0 0 24px;
        width: 24px;
        height: 16px;
        margin: 0 7px;
    }

    .country-code {
        position: absolute;
        left: 44px;
        font-size: 14px;
        line-height: 1.1rem;
        color: $grey-text;
        @include ios_le_12 {
            top: 50%;
            transform: translateY(-50%);
        }

        [disabled] & {
            font-weight: bold;
        }
    }
}
</style>

export default function getEventFilterDaysList() {
    const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const now = new Date();
    const todayIndex = now.getDay();

    // Utility to get ISO date range for a specific day offset
    const getDayRange = (offset) => {
        const date = new Date(now);
        date.setDate(date.getDate() + offset);
        const startOfDay = new Date(date).setUTCHours(0, 0, 0, 0);
        const endOfDay = new Date(date).setUTCHours(23, 59, 59, 999);
        return {
            start: new Date(startOfDay).toISOString(),
            end: new Date(endOfDay).toISOString(),
        };
    };

    // Generate day data based on offset
    const getDayData = (offset) => {
        const dayIndex = (todayIndex + offset) % 7;
        const dayName = dayNames[dayIndex];
        const { start, end } = getDayRange(offset);

        return {
            key: offset === 0 ? 'today' : offset === 1 ? 'tomorrow' : dayName,
            dayName,
            day: start.slice(0, 10), // Extract YYYY-MM-DD
            range: { gte: start, lt: end },
        };
    };

    // Build the result array
    return [
        { key: 'all', day: 'all', range: null }, // "All" entry
        ...Array.from({ length: 7 }, (_, i) => getDayData(i)), // Today + Next 6 Days
    ];
}

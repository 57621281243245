<template>
    <div v-if="!games.isLoading" class="casino-pills">
        <Tabs :tabs="tabs" :active="currentTab" class="casino-tabs" tab-type="round" scrollable @select="setCurrentTab" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { routeName } from '@/router/const-name';
import { Tabs } from '@/modules/core/components';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { getter as casinoGetter } from '@/store/modules/casino/const';
import { CasinoFilter, CasinoStaticCategories } from '@/modules/casino/utils/CasinoCollection-const';

export default {
    name: 'CasinoPills',
    components: { Tabs },
    props: {
        data: Object,
    },
    computed: {
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            gameCategories: casinoGetter.GET_GAME_CATEGORIES,
            games: casinoGetter.GET_GAMES,
        }),
        isAllGamesActive() {
            return !this.isHomeActive && this.$route.query[CasinoFilter.CATEGORY] === CasinoStaticCategories.ALL;
        },
        isFavGamesActive() {
            return this.$route.query[CasinoFilter.FAVOURITES];
        },
        isHomeActive() {
            return this.$route.name === routeName.CASINO && Object.keys(this.$route.query).length === 0;
        },
        activeCategory() {
            return this.$route.query[CasinoFilter.CATEGORY];
        },
        currentTab() {
            let activeKey = CasinoStaticCategories.HOME;
            if (this.isFavGamesActive) {
                activeKey = CasinoStaticCategories.FAVOURITES;
            } else if (this.isAllGamesActive) {
                activeKey = CasinoStaticCategories.ALL;
            } else if (this.activeCategory) {
                activeKey = this.activeCategory;
            }
            return {
                key: activeKey,
            };
        },
        tabs() {
            const tabs = [
                {
                    key: CasinoStaticCategories.HOME,
                    text: this.$t('ui.casinoLobby.home'),
                },
                {
                    key: CasinoStaticCategories.ALL,
                    text: this.$t('ui.eventPage.category.all'),
                },
                ...this.gameCategories.map((c) => ({
                    key: c.id,
                    text: c.text,
                    categoryId: c.id,
                })),
            ];
            if (this.isAuthenticated) {
                tabs.unshift({
                    key: CasinoStaticCategories.FAVOURITES,
                    disableNoGrow: true,
                    iconId: 'icon-star-tab',
                    iconClass: 'icon-size-small',
                });
            }
            return tabs;
        },
    },
    methods: {
        openCasinoCategory(categoryId) {
            this.replaceParams({ [CasinoFilter.CATEGORY]: `${categoryId}` });
        },
        replaceParams(query) {
            const defaultQuery = {};
            Object.values(CasinoFilter).forEach((value) => {
                defaultQuery[value] = undefined;
            });
            this.$router.push({ name: routeName.CASINO, query: { ...this.$route.query, ...defaultQuery, ...query } });
        },

        getGtmCategoryName(key) {
            if (!Object.values(CasinoStaticCategories).includes(key)) {
                key = `CATEGORY_${key}`;
            }
            return `CASINO_PILL_${key}`;
        },

        setCurrentTab(tab) {
            if (this.currentTab.key === tab.key) return;
            this.$gtm.query({
                event: 'casino_categories_tab',
                category_name: this.getGtmCategoryName(tab.key),
                jurisdiction: this.country,
                isAuthenticated: this.isAuthenticated,
            });

            switch (tab.key) {
                case CasinoStaticCategories.HOME:
                    return this.openCasino();
                case CasinoStaticCategories.FAVOURITES:
                    return this.openCasinoFavourites();
                case CasinoStaticCategories.ALL:
                    return this.openCasinoGames();
                default:
                    if (tab.categoryId) {
                        return this.openCasinoCategory(tab.categoryId);
                    }
                    return this.openCasino();
            }
        },
        openCasinoFavourites() {
            this.replaceParams({ [CasinoFilter.FAVOURITES]: 'true' });
        },
        openCasinoGames() {
            this.replaceParams({ [CasinoFilter.CATEGORY]: CasinoStaticCategories.ALL });
        },
        openCasino() {
            this.$router.replace({ name: routeName.CASINO });
        },
    },
};
</script>

<style scoped lang="scss">
.casino-pills {
    margin: 12px 0;
    max-width: 100%;

    &-item {
        flex-shrink: 0;
        padding: 6px 16px;
        border-radius: 20px;
        border: 1px solid $casino-pill-border;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &-text {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            &-icon {
                margin-right: 4px;
            }

            &-name {
                color: $casino-pill-text-color;
                @extend %link-small-font;
                line-height: 10px;
            }
        }

        &.casino-pills-item-icon-only {
            padding: 6px 8px;

            .casino-pills-item-text-icon {
                margin-right: 0;
            }
        }

        &.casino-pills-item-active {
            background-color: $surface-success;
            border-color: #9ce800;
            border-width: 2px;
            margin: -1px;
        }
    }
}

::v-deep .tabs-menu {
    padding: 0 12px;
}

::v-deep .tab-favourites {
    svg {
        position: relative;
        top: -2px;
        margin-right: 5px;
        fill: $betpawa-black;
    }

    &.active svg {
        fill: $menu-item-color-selected;
    }
}
</style>

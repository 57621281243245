import { widgetType } from '@/modules/sport/const/widget-const';
import {getObjectField} from "@/modules/core/utils/helper";

const defaultConfig = {
    widgetPriority: {
        upcoming: widgetType.SPORT_RADAR,
        live: widgetType.GENIUSSPORTS,
    },
    convertIdToNumber: true,
    defaultValue: {},
};

export function getEventWidget(event, config = defaultConfig) {
    const {
        wantedWidgetType,
        widgetPriority,
        convertIdToNumber,
        defaultValue
    } = { ...defaultConfig, ...config };

    if (!event || !Array.isArray(event.widgets)) {
        return defaultValue;
    }

    const fallbackWidgetType = getObjectField(event, 'additionalInfo.live', false)
        ? widgetPriority.live
        : widgetPriority.upcoming;
    const desiredWidgetType = wantedWidgetType || fallbackWidgetType;
    const foundWidget = event.widgets.find(({ type }) => type === desiredWidgetType);

    if (foundWidget) {
        return {
            ...foundWidget,
            id: convertIdToNumber ? Number(foundWidget.id) : foundWidget.id,
        };
    }

    return getObjectField(event, 'widgets.0', defaultValue);
}


<template>
    <div class="otp-verification-remaining-wrapper">
        <span>
            {{ remainingRequestsMessage }}
        </span>

        <Badge class="pointer" icon-name="icon-about-menu" @click.native.prevent="$modal.show('verification-code-info')" />

        <Modal
            name="verification-code-info"
            :width="'80%'"
            class="otp-verification-remaining-tooltip"
            @close="$modal.hide('verification-code-info')"
        >
            <renderer :input="$t('ui.accountVerification.tooltipMessage')" />
        </Modal>
    </div>
</template>

<script>
import Badge from '@/components/Badge.vue';

export default {
    name: 'OtpVerificationRemaining',
    components: {
        Badge,
    },
    props: {
        attemptsLeft: Number,
    },
    computed: {
        remainingRequestsMessage() {
            if (this.attemptsLeft > 1) {
                return this.$t('ui.accountVerification.remainingRequests', { remainingRequests: this.attemptsLeft });
            }

            if (this.attemptsLeft === 1) {
                return this.$t('ui.accountVerification.remainingRequest', { remainingRequests: this.attemptsLeft });
            }

            return this.$t('ui.accountVerification.noRemainingRequestsLeft');
        },
    },
};
</script>

<style lang="scss" scoped>
.otp-verification-remaining-wrapper {
    display: table;
    margin: 0 auto 20px auto;

    @extend %small-details-font-400;
    text-align: center;

    & span {
        display: table-cell;
        vertical-align: middle;
    }

    ::v-deep {
        .badge {
            padding-top: 0;
            padding-bottom: 1px;
        }
        .icon {
            width: 14px;
            height: 14px;
            margin: 0;
        }
    }
}

.otp-verification-remaining-tooltip {
    span {
        @extend %body-normal-font-400;
        text-align: start;
    }

    ::v-deep {
        .modal-body {
            padding: 0 16px 0;
        }

        .modal-container {
            padding-bottom: 16px;
        }
    }
}
</style>

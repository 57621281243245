<template>
    <form data-test-id="resetPasswordStep" @submit.prevent>
        <h2 class="reset-password-step-title">
            {{ $t(`ui.accountVerification.${pinOrPassword}.secondStepName`) }}
        </h2>
        <span class="reset-password-step-sub-header">
            <renderer :input="$t(`ui.accountVerification.${pinOrPassword}.secondStepDescription`)" />
        </span>

        <Password
            name="newPassword"
            data-test-id="newPasswordInput"
            :label="$t(`ui.accountVerification.${pinOrPassword}.newLabel`)"
            :form-name="formNameId"
            :value="newPassword"
            :v="$v.newPassword"
            :error-messages="errorMessages"
            @value="newPassword = $event"
        />

        <Password
            name="newPasswordConfirm"
            data-test-id="newPasswordConfirmInput"
            :label="$t(`ui.common.${pinOrPassword}.confirmNewPassword`)"
            :form-name="formNameId"
            :value="newPasswordConfirm"
            :v="$v.newPasswordConfirm"
            :error-messages="errorMessages"
            @value="newPasswordConfirm = $event"
        />

        <div v-if="error" class="reset-password-step-error">
            <renderer :input="error" class="notify error" />
        </div>

        <button
            data-test-id="saveNewPasswordButton"
            class="button button-primary button-full reset-password-step-save-button"
            :disabled="$v.$invalid || isSaveNewPasswordInProgress"
            @click="handleSaveNewPassword()"
        >
            <template v-if="isSaveNewPasswordInProgress">
                <Spinner visible :zoom="60" class="justify-center" />
            </template>
            <template v-else>
                {{ $t(`ui.accountVerification.${pinOrPassword}.saveNewButton`) }}
            </template>
        </button>
    </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { minLength, numeric, required, sameAs } from 'vuelidate/lib/validators';
import { routeName } from '@/router/const-name';
import { ACCOUNT_SELF_EXCLUDED_ERROR, modalNames } from '@/modules/platform/const/verification';

import { getter as coreGetter } from '@/modules/core/store/const';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { action as authAction, getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { getter as translationsGetter } from '@/store/modules/translations/const';
import { action as storeAction } from '@/store/const';

import Password from '@/components/Password';

export default {
    name: 'ResetPasswordStep',
    components: { Password },
    props: {
        verifiedOtpCode: {
            type: String,
            require: true,
        },
        phoneNumber: {
            type: String,
            require: true,
        },
    },
    data() {
        return {
            formNameId: 'reset-password-form',
            newPassword: '',
            newPasswordConfirm: '',
        };
    },
    validations() {
        const isPin = this.brandPreference.pin;

        return {
            newPassword: {
                required,
                minLength: minLength(4),
                newPassword: (_, vm) => {
                    const { required, minLength, newPasswordConfirm } = vm.$v.newPasswordConfirm;

                    return !(required && minLength && !newPasswordConfirm);
                },
                differentFromOtp: (password) => password !== this.verifiedOtpCode,
                ...(isPin && { numeric }),
            },
            newPasswordConfirm: {
                required,
                minLength: minLength(4),
                newPasswordConfirm: sameAs('newPassword'),
                differentFromOtp: (password) => password !== this.verifiedOtpCode,
                ...(isPin && { numeric }),
            },
        };
    },
    computed: {
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            pinOrPassword: translationsGetter.PIN_OR_PASSWORD,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            authError: authGetter.GET_AUTH_ERROR,
        }),
        isSaveNewPasswordInProgress() {
            return this.isLoading(authAction.UPDATE_AND_AUTHENTICATE);
        },
        error() {
            return this.authError;
        },
        errorMessages() {
            const pinMismatchError = this.$t(`ui.accountVerification.errors.${this.pinOrPassword}.pinMismatch`);
            const codeCannotBePinError = this.$t(`ui.accountVerification.errors.${this.pinOrPassword}.codeCannotBePin`);

            return {
                newPassword: pinMismatchError,
                newPasswordConfirm: pinMismatchError,
                differentFromOtp: codeCannotBePinError,
            };
        },
    },
    methods: {
        handleSaveNewPassword() {
            this.$store
                .dispatch(authAction.UPDATE_AND_AUTHENTICATE, {
                    phoneNumber: this.phoneNumber,
                    otpCode: this.verifiedOtpCode,
                    newPassword: this.newPassword,
                })
                .then(() => {
                    this.$router.push({ name: routeName.HOMEPAGE }).then(() => {
                        this.$store.dispatch(authAction.GET_BALANCE, { force: true });
                        this.$store.dispatch(storeAction.NOTIFY, {
                            type: 'reset',
                            data: {
                                trigger: true,
                            },
                        });
                    });
                })
                .catch(({ errorCode }) => {
                    if (errorCode === ACCOUNT_SELF_EXCLUDED_ERROR) {
                        this.$router.push({ name: routeName.HOMEPAGE }).then(() => {
                            this.$modal.show(modalNames.SELF_EXCLUSION_MODAL);
                        });
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.reset-password-step-title {
    text-align: center;
    margin-bottom: 8px;
}

.reset-password-step-sub-header {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
}

.reset-password-step-error {
    display: flex;
    margin-bottom: 16px;

    & .notify {
        margin: 0;
        width: 100%;
    }
}

.reset-password-step-save-button {
    margin-bottom: 8px;

    .spinner {
        position: relative;
        background-color: transparent;
        padding: 0;
        max-height: 17px;
    }
}
</style>

import { routeName } from '@/router/const-name';

export const EventType = {
    UPCOMING: 'UPCOMING',
    LIVE: 'LIVE',
};

export const EventCategory = {
    EVENTS: 'events',
    BOOSTED: 'boosted',
    POPULAR: 'popular',
};

export const EventCountKeysByPage = {
    [routeName.LIVE]: 'live',
    [routeName.UPCOMING]: 'upcoming',
    [routeName.BOOSTED_EVENTS]: 'boosted',
    [routeName.POPULAR]: 'hot',
    [routeName.GROUP]: 'upcoming',
};

export const EVENTS_SORTING_ACCEPTABLE_MAP = {
    competitionPriority_DESC: 'competitionPriority_DESC',
    startTime_DESC: 'startTime_DESC',
    startTime_ASC: 'startTime_ASC',
    competitionAbc_DESC: 'competitionAbc_DESC',
    competitionAbc_ASC: 'competitionAbc_ASC',
};

export const DEFAULT_FILTER_BY_DAY = 'all';

export const DEFAULT_TAKE = 100;

export const SPORT_CATEGORY = {
    FOOTBALL: '2',
    BASKETBALL: '3',
    TENNIS: '452',
};

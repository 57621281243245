import Vue from 'vue';
import { actionLoader, helper } from '@/modules/core';
import { mutation as coreMutation } from '@/modules/core/store/const';
import { numberFormat } from '@/modules/core/utils/helper';

import { getter as platformGetter, mutation as platformMutation } from '@/modules/platform/store/const';
import { action as authAction } from '@/modules/platform/store/modules/auth/const';

import { getter as sportGetter, mutation as sportMutation } from '@/modules/sport/store/const';
import { betslip as betslipEndpoints, pricing } from '@/modules/sport/endpoints';
import { processBookingSelection } from '@/modules/sport/store/utils';
import { directShareLinkTemplateFn } from '@/modules/sport/utils/betslip/directShareLinkTemplateFn';
import { getSocialLinks } from '@/modules/sport/utils/betslip/getSocialLinks';
import { getBetSharingLinks } from '@/modules/sport/utils/betslip/getBetSharingLinks';
import { betslipType } from '@/modules/sport';

import { toDiffInSeconds } from '@/js/utils/dateTime/toDiffInSeconds';

import { action as generalAction } from '@/store/const'; // move to core BP-15141
import { getLocalTypes } from '@/store/utils'; // move to core BP-15141
import { action, getter, mutation } from '@/modules/sport/store/modules/betslip/const';

const BET_PLACEMENT_MAX_ATTEMPTS = 5;
const STORE_SELECTED_TYPE_PROPERTY = 'selectedType';

const _action = getLocalTypes(action);
const _mutation = getLocalTypes(mutation);
const _getter = getLocalTypes(getter);

/// Store
const BETSLIP_STATE = {
    type: null,
    odds: null,
    status: {},
    bets: [],
    selectedBetsIds: [],
    error: {
        code: null,
        message: null,
    },
    stake: null,
    pollingStartedTimestamp: null,
    pollingTimeoutId: null,
    pollingAttempt: 1,
    uuid: '',
    timer: 0,
    pollingInterval: null,
};

const BOOKING_STATE = {
    created: {
        code: null,
        expires: null,
        error: null,
        selectionIds: [],
        lastSelectionIds: null,
    },
    loaded: {
        code: null,
        selection: [],
        expires: null,
        error: null,
    },
    ui: {
        isBookingCodeLoadingEnabled: true,
    },
    lastSocialShareTextKey: null,
};

const state = {
    selectedEventId: null,
    selectedPriceId: null,
    [STORE_SELECTED_TYPE_PROPERTY]: betslipType.REGULAR,
    [betslipType.VIRTUAL]: {
        ...BETSLIP_STATE,
        season: {},
    },
    [betslipType.REGULAR]: {
        ...BETSLIP_STATE,
        acceptAnyPrice: { local: false, localExpires: null },
    },
    shareLinks: null,
    externalIdsPrefillError: null,
    booking: { ...BOOKING_STATE },
};

const getters = {
    [_getter.GET_BETS]: (state) => state[betslipType.REGULAR].bets,
    [_getter.GET_VIRTUAL_BETS]: (state) => state[betslipType.VIRTUAL].bets,
    [_getter.GET_ALL_BETS]: (state) => {
        return [
            { type: betslipType.REGULAR, bets: [...state[betslipType.REGULAR].bets] },
            { type: betslipType.VIRTUAL, bets: [...state[betslipType.VIRTUAL].bets] },
        ].flatMap(({ type, bets }) => bets.map((it) => ({ ...it, type, isVirtual: type === betslipType.VIRTUAL })));
    },
    [_getter.GET_BETSLIP_BOOKING_CODE]: (state) => state.booking.created.code,
    [_getter.GET_BETSLIP_BOOKING_ERROR]: (state) => state.booking.created.error,
    [_getter.GET_BETSLIP_BOOKING_LAST_SELECTIONS]: (state) => state.booking.created.lastSelectionIds,
    [_getter.GET_SHARE_LINKS]: (state) => state.shareLinks,
    // avoid caching result of the following getter
    [_getter.GET_OFFLINE_PRICES]: (state, getters) => {
        const betslip = getters[_getter.GET_SELECTED_BETSLIP];
        const now = new Date().toISOString();
        return betslip.bets
            .filter((event) => !event.isLive || !event.price.PriceRaw)
            .reduce(function (prices, { rawDate, price }) {
                const isOutdated = rawDate <= now;
                const isOffline = !price.PriceRaw;
                return [...prices, ...(isOffline || isOutdated ? [price.Id] : [])];
            }, []);
    },
    [_getter.ALL_PRICES_OFFLINE]: (state, getters) => {
        const betslip = getters[_getter.GET_SELECTED_BETSLIP];
        const offlinePrices = getters[_getter.GET_OFFLINE_PRICES];
        return !!betslip.bets.length && betslip.bets.length === offlinePrices.length;
    },
    [_getter.GET_TOTAL_ODDS]: (state, getters) => {
        const betslip = getters[_getter.GET_SELECTED_BETSLIP];
        const offlinePrices = getters[_getter.GET_OFFLINE_PRICES];
        const total = betslip.bets.reduce(
            (total, bet) => (bet.price.PriceRaw && !offlinePrices.includes(bet.price.Id) ? total * bet.price.PriceRaw : total),
            1
        );
        return helper.rounded(total, 3, Math.floor);
    },
    [_getter.GET_SELECTED_BETSLIP]: getSelectedBetslip,
    [_getter.GET_REGULAR_BETSLIP]: (state) => state[betslipType.REGULAR],
    [_getter.GET_VIRTUAL_BETSLIP]: (state) => state[betslipType.VIRTUAL],
    [_getter.GET_BETSLIP_TYPE]: (state) => state[STORE_SELECTED_TYPE_PROPERTY],
    [_getter.IS_VIRTUAL_BETSLIP]: (state) => state[STORE_SELECTED_TYPE_PROPERTY] === betslipType.VIRTUAL,
    [_getter.GET_BETSLIP_POLLING_ATTEMPTS]: (state) => {
        return getSelectedBetslip(state).pollingAttempt;
    },
    [_getter.GET_BETSLIP_POLLING_START_TIMESTAMP]: (state) => {
        return getSelectedBetslip(state).pollingStartedTimestamp;
    },
    [_getter.GET_BETSLIP_BETS_COUNT]: (state) => (!state[betslipType.REGULAR].status.Placed ? state[betslipType.REGULAR].bets.length : 0),
    [_getter.GET_VIRTUAL_BETSLIP_BETS_COUNT]: (state) =>
        !state[betslipType.VIRTUAL].status.Placed ? state[betslipType.VIRTUAL].bets.length : 0,
    [_getter.GET_ACCEPT_ANY_PRICE]: (state, getters, rootState, rootGetters) => {
        const { local, localExpires } = state[betslipType.REGULAR].acceptAnyPrice;
        const { preference } = rootGetters[platformGetter.GET_SETTINGS];
        const remote = !!preference.accept_odds;
        const now = new Date();
        if (!localExpires || now.getTime() - Date.parse(localExpires) > 0) {
            return remote || false;
        }
        return local;
    },
    [_getter.GET_TAX_TYPE]: (state, getters, rootState, rootGetter) => {
        return getters[_getter.IS_VIRTUAL_BETSLIP]
            ? rootGetter[platformGetter.GET_SETTINGS].taxBrand.virtual.win.type
            : rootGetter[platformGetter.GET_SETTINGS].taxBrand.real.win.type;
    },
    [_getter.GET_STAKE]: (state, getters) => {
        return getters[_getter.GET_SELECTED_BETSLIP].stake;
    },
    [_getter.GET_MULTIPLIER]: (state, getters) => {
        return getters[_getter.GET_CURRENT_BONUS] + 1;
    },
    [_getter.GET_STAKE_AFTER_TAX]: (state, getters, rootState, rootGetter) => {
        const rate = getters[_getter.IS_VIRTUAL_BETSLIP]
            ? rootGetter[platformGetter.GET_VIRTUAL_STAKE_TAX_AMOUNT]
            : rootGetter[platformGetter.GET_STAKE_TAX_AMOUNT];
        const taxAmount = (getters[_getter.GET_IS_TAX_ENABLED] && rate) || 0;
        return helper.rounded(getters[_getter.GET_STAKE] / (taxAmount + 1));
    },
    [_getter.GET_MULTIPLIER_TOTAL_ODDS]: (state, getters, rootState, rootGetter) => {
        const total = rootGetter[platformGetter.IS_MULTIPLIER_SCHEMA]
            ? getters[_getter.GET_TOTAL_ODDS] * getters[_getter.GET_MULTIPLIER]
            : getters[_getter.GET_TOTAL_ODDS];
        return helper.rounded(total, 3);
    },
    [_getter.GET_TOTAL_WINNINGS]: (state, getters) => {
        const total =
            getters[_getter.GET_MULTIPLIER_TOTAL_ODDS] * getters[_getter.GET_STAKE_AFTER_TAX] - getters[_getter.GET_STAKE_AFTER_TAX];
        return helper.rounded(total);
    },
    [_getter.GET_TOTAL_WIN_BONUS]: (state, getters, rootState, rootGetter) => {
        return !rootGetter[platformGetter.IS_MULTIPLIER_SCHEMA]
            ? getters[_getter.GET_CURRENT_BONUS] * getters[_getter.GET_TOTAL_WINNINGS]
            : 0;
    },
    [_getter.GET_TOTAL_BONUS]: (state, getters) => {
        const bonuses = getters[_getter.GET_BONUSES];
        return bonuses.length ? Math.floor(100 * bonuses[bonuses.length - 1].bonus) : 0;
    },
    [_getter.GET_CURRENT_BONUS]: (state, getters, rootState, rootGetter) => {
        if (getters[_getter.GET_TOTAL_ODDS] < rootGetter[platformGetter.GET_SETTINGS].bonus.minimumTotalOdds) {
            return 0;
        }
        const bonuses = getters[_getter.GET_BONUSES];
        return bonuses && bonuses.length && bonuses[getters[_getter.GET_CURRENT_BONUS_INDEX]].bonus;
    },
    [_getter.GET_BETS_WITH_BONUS]: (state, getters) => {
        return getters[_getter.GET_SELECTED_BETSLIP].bets.filter(
            ({ price }) => !getters[_getter.GET_OFFLINE_PRICES].includes(price.Id) && price.PriceRaw && price.EligibleForBonus
        );
    },
    [_getter.GET_CURRENT_BONUS_INDEX]: (state, getters) => {
        if (!getters[_getter.GET_BONUSES].length) {
            return 0;
        }
        for (let i = getters[_getter.GET_BONUSES].length - 1; i >= 0; i--) {
            if (getters[_getter.GET_BETS_WITH_BONUS].length >= getters[_getter.GET_BONUSES][i].selections) {
                return i;
            }
        }
        return 0;
    },
    [_getter.GET_MAX_BONUS_PERCENTAGE]: (state, getters, rootState, rootGetter) => {
        const { perLeg } = rootGetter[platformGetter.GET_SETTINGS].bonus || {};
        return [...(perLeg || [])].pop() * 100 || '';
    },
    [_getter.GET_BONUSES]: (state, getters, rootState, rootGetter) => {
        if (!rootGetter[platformGetter.GET_SETTINGS].bonus || !rootGetter[platformGetter.GET_SETTINGS].bonus?.perLeg) {
            return [];
        }
        return rootGetter[platformGetter.GET_SETTINGS].bonus?.perLeg?.reduce((prevValue, bonus, selections) => {
            const leg = prevValue.filter((item) => item.bonus === bonus);
            if (!leg.length) {
                prevValue.push({ selections, bonus });
            }
            return prevValue;
        }, []);
    },
    [_getter.GET_LEG_LIMIT]: (state, getters) => {
        const bonuses = getters[_getter.GET_BONUSES].length;
        return bonuses && bonuses + 1;
    },
    [_getter.GET_SHOW_LEG_INFO]: (state, getters) => {
        if (
            !getters[_getter.GET_BONUSES].length ||
            getters[_getter.ALL_PRICES_OFFLINE] ||
            getters[_getter.GET_SELECTED_BETSLIP].status.Placed
        ) {
            return false;
        }

        const index = getters[_getter.GET_CURRENT_BONUS_INDEX];
        const bonus = getters[_getter.GET_BONUSES][index];

        const legNextTier = getters[_getter.GET_BONUSES][index + 1]
            ? getters[_getter.GET_BONUSES][index + 1]
            : { selections: index, bonus: null };
        if (!legNextTier.bonus && getters[_getter.GET_BETS_WITH_BONUS].length >= bonus.selections) {
            return true;
        }
        return (
            getters[_getter.GET_BETS_WITH_BONUS].length &&
            getters[_getter.GET_BETS_WITH_BONUS].length >= bonus.selections &&
            getters[_getter.GET_BETS_WITH_BONUS].length < legNextTier.selections
        );
    },
    [_getter.HAS_BONUSES_DATA]: (state, getters) => {
        return getters[_getter.GET_BONUSES].length > 0;
    },
    [_getter.GET_LEG_INFO_DATA]: (state, getters) => {
        const index = getters[_getter.GET_CURRENT_BONUS_INDEX];
        const bonus = getters[_getter.GET_BONUSES][index] ? getters[_getter.GET_BONUSES][index].bonus : 0;
        const tierIndex = getters[_getter.GET_BONUSES].length > index ? index : index - 1;
        const nextTier = getters[_getter.GET_BONUSES][tierIndex + 1]
            ? getters[_getter.GET_BONUSES][tierIndex + 1]
            : { selections: tierIndex, bonus: null };
        const selectionsToNextTier = nextTier.selections - getters[_getter.GET_BETS_WITH_BONUS].length;
        const currentWinBonus = helper.rounded(100 * bonus);
        return {
            selectionsToNextTier,
            currentWinBonus,
            nextTierPercentage: helper.rounded(100 * (nextTier.bonus ? nextTier.bonus : 0)),
            currentSelections: getters[_getter.GET_BETS_WITH_BONUS].length,
        };
    },
    [_getter.GET_IS_TAX_ENABLED]: (state, getters, rootState, rootGetter) => {
        return getters[_getter.IS_VIRTUAL_BETSLIP]
            ? rootGetter[platformGetter.IS_VIRTUAL_TAX_ENABLED]
            : rootGetter[platformGetter.IS_TAX_ENABLED];
    },
    [_getter.GET_IS_WIN_TAX_ENABLED]: (state, getters, rootState, rootGetter) => {
        return getters[_getter.IS_VIRTUAL_BETSLIP]
            ? rootGetter[platformGetter.IS_VIRTUAL_WIN_TAX_ENABLED]
            : rootGetter[platformGetter.IS_WIN_TAX_ENABLED];
    },
    [_getter.GET_IS_WHT]: (state, getters) => {
        const whtTax = {
            WHT_GROSS: 'WHT_GROSS',
            WHT_NET: 'WHT_NET',
            WHT_NET_TIERED: 'WHT_NET_TIERED',
        };
        return getters[_getter.GET_IS_WIN_TAX_ENABLED] && Object.values(whtTax).includes(getters[_getter.GET_TAX_TYPE]);
    },
    [_getter.GET_SELECTION_IDS]: (state) => getSelectedBetslip(state).selectedBetsIds,
    [_getter.GET_PLACED_TYPES]: (state) => {
        const placedTypes = [];
        Object.values(betslipType).forEach((bType) => {
            if (state[bType] && state[bType].status.Placed) {
                placedTypes.push(bType);
            }
        });
        return placedTypes;
    },
    [_getter.GET_SELECTION_IDS_BY_TYPE]: (state) => (type) => {
        return state[type] && state[type].selectedBetsIds ? state[type].selectedBetsIds : [];
    },
};

const mutations = {
    [_mutation.ADD_BETSLIP_BET](state, { bet, type = betslipType.REGULAR, skipJoin = false }) {
        if (!skipJoin) {
            state[type].bets = [...state[type].bets, bet];
        }
        state.selectedEventId = bet.eventId;
        state.selectedPriceId = bet.price.Id;
    },
    [_mutation.SET_BETSLIP_BETS](state, { bets = [], type = betslipType.REGULAR }) {
        state[type].selectedBetsIds = bets.map(({ price }) => price.Id);
        state[type].bets = bets;
        state.externalIdsPrefillError = null;
    },
    [_mutation.REMOVE_BETSLIP_BET](state, { priceId, type = betslipType.REGULAR }) {
        const bets = state[type]?.bets;
        const selectedBet = !!bets && bets.find((bet) => bet.price.Id === priceId);
        if (selectedBet) {
            const remainingBets = bets.filter((bet) => bet.price.Id !== priceId);
            state.selectedEventId = remainingBets.length ? remainingBets[remainingBets.length - 1].eventId : null;
            state.selectedPriceId = selectedBet.price.Id;
            state[type].bets = [...remainingBets];
        }

        const remainingBetsIds = state[type].selectedBetsIds.filter((id) => id !== priceId);
        state[type].selectedBetsIds = [...remainingBetsIds];
    },
    [_mutation.REMOVE_ALL_BETSLIP_BETS](state, type = betslipType.REGULAR) {
        state[type].bets = [];
        state[type].selectedBetsIds = [];
        state.selectedEventId = null;
        state.selectedPriceId = null;
    },
    [_mutation.CHANGE_BETSLIP_BET](state, { bet, index, type = betslipType.REGULAR }) {
        state.selectedEventId = bet.eventId;
        state.selectedPriceId = bet.price.Id;
        Vue.set(state[type].bets, index, bet);
    },
    [_mutation.CLEAR_SELECTED_EVENT_ID](state) {
        state.selectedEventId = null;
        state.selectedPriceId = null;
    },
    [_mutation.SET_SHARE_LINKS](state, link) {
        state.shareLinks = link || null;
    },
    [_mutation.CLEAR_SHARE_LINKS](state) {
        state.shareLinks = null;
    },
    [_mutation.SET_CREATED_BOOKING_CODE](state, { code, expires, selectionIds }) {
        state.booking.created = { code, expires, error: null, selectionIds };
    },
    [_mutation.SET_CREATED_BOOKING_CODE_ERROR](state, { error, lastSelectionIds }) {
        state.booking.created.error = error;
        state.booking.created.lastSelectionIds = lastSelectionIds;
    },
    [_mutation.RESET_CREATED_BOOKING_CODE](state) {
        state.booking.created = { ...BOOKING_STATE.created };
    },
    [_mutation.RESET_CREATED_BOOKING_CODE_ERRORS](state) {
        state.booking.created.error = null;
        state.booking.created.lastSelectionIds = null;
    },
    [_mutation.RESET_LOADED_BOOKING_CODE_ERRORS](state) {
        state.booking.loaded.error = null;
    },
    [_mutation.SET_BOOKING_SELECTION](state, { selection, code }) {
        state.booking.loaded = { selection, code, error: null };
    },
    [_mutation.SET_BOOKING_SELECTION_ERROR](state, error) {
        state.booking.loaded.error = error;
    },
    [_mutation.TOGGLE_BOOKING_CODE_LOADING](state) {
        state.booking.ui.isBookingCodeLoadingEnabled = !state.booking.ui.isBookingCodeLoadingEnabled;
    },
    [_mutation.SET_BETSLIP_STATUS](state, { status, type = betslipType.REGULAR }) {
        state[type].status = status;
    },
    [_mutation.RESET_BETSLIP_STATUS](state, type = betslipType.REGULAR) {
        state[type].uuid = helper.createUUID();
        state[type].status = {};
        state.externalIdsPrefillError = null;
    },
    [_mutation.SET_BETSLIP_CURRENT_INTERVAL](state, intervalId) {
        const betslip = getSelectedBetslip(state);
        betslip.pollingInterval = intervalId;
    },
    [_mutation.RESET_BETSLIP_POLLING](state) {
        const betslip = getSelectedBetslip(state);

        clearTimeout(betslip.pollingTimeoutId);
        betslip.pollingTimeoutId = null;
        betslip.pollingStartedTimestamp = null;
        betslip.pollingAttempt = 1;
        betslip.pollingInterval = null;
    },
    [_mutation.UPDATE_BETSLIP_POLLING_TIMER](state, value) {
        const betslip = getSelectedBetslip(state);
        const defaultValue = betslip.timer <= 0 ? 0 : --betslip.timer;
        betslip.timer = value || defaultValue;
    },
    [_mutation.SET_BETSLIP_TIMEOUT_ID](state, timeoutId) {
        getSelectedBetslip(state).pollingTimeoutId = timeoutId;
    },
    [_mutation.BETSLIP_POLLING_CLEAR_INTERVAL](state) {
        const betslip = getSelectedBetslip(state);
        clearInterval(betslip.pollingInterval);
    },
    [_mutation.UPDATE_BETSLIP_POLLING_ATTEMPT](state, value) {
        getSelectedBetslip(state).pollingAttempt = value;
    },
    [_mutation.SET_BETSLIP_POLLING_START_TIMESTAMP](state) {
        getSelectedBetslip(state).pollingStartedTimestamp = Date.now();
    },
    [_mutation.SET_BETSLIP_ERROR](state, { message, code, type }) {
        state[type].error = { message, code };
    },
    [_mutation.RESET_BETSLIP_ERROR](state, type) {
        state[type].error = BETSLIP_STATE.error;
    },
    [_mutation.SET_BETSLIP_STAKE](state, { stake, type }) {
        state[type].stake = stake;
    },
    [_mutation.RESET_BETSLIP_STAKE](state, type) {
        state[type].stake = null;
    },
    [_mutation.SET_BETSLIP_ACCEPT_ANY_PRICE](state, local) {
        const twoMinutes = 2 * 60 * 1000; // 2 mins backend cache, using local for that time
        const localExpires = new Date(Date.now() + twoMinutes);

        state[betslipType.REGULAR].acceptAnyPrice = {
            ...state[betslipType.REGULAR].acceptAnyPrice,
            ...{
                localExpires,
                local,
            },
        };
    },
    [_mutation.SET_SELECTED_BETSLIP](state, selectedType) {
        state[STORE_SELECTED_TYPE_PROPERTY] = selectedType;
    },
    [_mutation.SET_VIRTUAL_BETSLIP_SEASON](state, season) {
        state[betslipType.VIRTUAL].season = season;
    },
    [_mutation.RESET_VIRTUAL_BETSLIP_SEASON](state) {
        state[betslipType.VIRTUAL].season = {};
    },
    [_mutation.GENERATE_BETSLIP_ID](state, type = betslipType.REGULAR) {
        state[type].uuid = helper.createUUID();
    },
    [_mutation.SET_EXTERNAL_PREFILL_ERROR](state, error) {
        state.externalIdsPrefillError = error;
    },
};

const actions = {
    [_action.RESET_USER_BETS_DATA]({ commit, dispatch, state }) {
        if (state[betslipType.REGULAR].status.Placed) {
            commit(_mutation.REMOVE_ALL_BETSLIP_BETS, betslipType.REGULAR);
        }

        if (state[betslipType.VIRTUAL].status.Placed) {
            commit(_mutation.REMOVE_ALL_BETSLIP_BETS, betslipType.VIRTUAL);
        }

        dispatch(_action.RESET_BETSLIP_STATUS);
        dispatch(_action.RESET_BETSLIP_ERROR);
    },
    [_action.TOGGLE_BET]({ commit, dispatch, state, getters }, bet) {
        const { type, replace, marketPrices } = bet;
        commit(_mutation.GENERATE_BETSLIP_ID, type);
        if (type !== betslipType.VIRTUAL) {
            commit(_mutation.RESET_CREATED_BOOKING_CODE);
        }
        const betslip = state[type];
        const betIndex = betslip.bets.findIndex((element) => element.eventId === bet.eventId);
        const selectionIdIndex = betslip.selectedBetsIds.findIndex((id) => id === bet.price.Id);
        const index = betslip.bets.length ? betIndex : selectionIdIndex;
        if (index === -1) {
            dispatch(_action.ADD_BETSLIP_BET, { bet, type });
        } else {
            if (betslip.bets[index].price.Id === bet.price.Id && !replace) {
                dispatch(_action.REMOVE_BET, bet);
            } else {
                commit(_mutation.CHANGE_BETSLIP_BET, { bet, index, type, marketPrices });
            }
        }
        dispatch(_action.RESET_BETSLIP_ERROR, type);
    },
    [_action.ADD_BETSLIP_BET]({ commit, dispatch, getters, state }, { bet, type }) {
        const getterType = type === betslipType.VIRTUAL ? _getter.GET_VIRTUAL_BETSLIP : _getter.GET_REGULAR_BETSLIP;
        const { selectedBetsIds } = getters[getterType];

        if (!state[type].bets.length && selectedBetsIds.length) {
            dispatch(_action.GET_SELECTIONS_BY_IDS, {
                selections: [...selectedBetsIds, bet.price.Id],
                type,
            }).then(() => {
                commit(_mutation.ADD_BETSLIP_BET, { bet, type, skipJoin: true });
            });
        } else {
            commit(_mutation.ADD_BETSLIP_BET, {
                bet,
                type,
            });
        }
    },
    [_action.SELECT_BETSLIP_TYPE]({ commit, rootGetters }) {
        const isVirtual = rootGetters['sport/isVirtualSport']; // import sport getter BP-16141
        commit(_mutation.SET_SELECTED_BETSLIP, isVirtual ? betslipType.VIRTUAL : betslipType.REGULAR);
    },
    // TODO: combine with toggleBet action
    [_action.REMOVE_BET]({ commit, state, dispatch, getters }, { eventId, price }) {
        const id = eventId; // refactor BP-16141
        const { type, isVirtual } = getters[_getter.GET_ALL_BETS].find(({ eventId }) => eventId === id);

        dispatch(_action.RESET_BETSLIP_ERROR, type);
        commit(_mutation.GENERATE_BETSLIP_ID, type);

        if (!isVirtual) {
            commit(_mutation.RESET_CREATED_BOOKING_CODE);
        }

        commit(_mutation.REMOVE_BETSLIP_BET, { priceId: price.Id, type });

        if (isVirtual && state[betslipType.VIRTUAL].bets.length === 0) {
            commit(_mutation.RESET_VIRTUAL_BETSLIP_SEASON);
        }
    },
    [_action.REMOVE_ALL_BETS]({ commit, dispatch }, type) {
        // convert into mutation BP-16141
        dispatch(_action.RESET_BETSLIP_ERROR, type);
        dispatch(_action.RESET_BETSLIP_STATUS, type);
        commit(_mutation.GENERATE_BETSLIP_ID, type);
        commit(_mutation.REMOVE_ALL_BETSLIP_BETS, type);
        if (type === betslipType.VIRTUAL) {
            commit(_mutation.RESET_VIRTUAL_BETSLIP_SEASON);
        } else {
            commit(_mutation.RESET_CREATED_BOOKING_CODE);
        }
    },
    [_action.COPY_BETS]: actionLoader(action.COPY_BETS, ({ dispatch, commit, state, rootState, rootGetters }, selectionIds) => {
        return Vue.$http
            .post(pricing.getPrices, { selections: selectionIds })
            .then(({ data }) => data.items || data)
            .then((selections) => {
                if (state.regular.status.Placed) {
                    commit(_mutation.REMOVE_ALL_BETSLIP_BETS);
                    commit(_mutation.RESET_BETSLIP_STATUS, betslipType.REGULAR);
                }

                const minimumOddsForBonus = rootGetters[platformGetter.GET_MINIMUM_ODDS_FOR_BONUS];
                const odds = rootState.sport.myBets.betslip.selections.filter((item) => selectionIds.includes(item.selection.id));

                if (!odds?.length) return;

                for (const odd of odds) {
                    const selection = selections.find((selection) => odd.selection.id === Number(selection.id));
                    const { price, pawaboost, cashoutable } = selection || {};

                    const currentSelection = {
                        Name: odd.selection.name,
                        Id: odd.selection.id,
                        Price: price ? price.toFixed(2) : null,
                        PriceRaw: selection ? price : null,
                        Hcp: odd.selection.market.handicap.text,
                        EligibleForBonus: price >= minimumOddsForBonus,
                        Pawaboost: selection ? pawaboost : '',
                        Cashoutable: selection ? cashoutable : undefined,
                        InPlay: odd.selection.inPlay,
                        twoUp: odd.twoUp,
                    };

                    dispatch(_action.TOGGLE_BET, {
                        market: {
                            name: odd.selection.market.name,
                            groupName: odd.selection.market.groupName,
                            displayName: odd.selection.market.displayName,
                            groupedMarketName: odd.selection.market.groupedName,
                            handicapType: odd.selection.market.handicap.type,
                            cashoutable: odd.cashoutable,
                            twoUp: odd.twoUp,
                        },
                        price: currentSelection,
                        name: odd.selection.event.name,
                        eventId: odd.selection.event.id,
                        isLive: currentSelection.InPlay,
                        type: betslipType.REGULAR,
                        replace: true,
                        rawDate: odd.selection.starts,
                    });
                }
            })
            .catch((error) => {
                commit('sport/setMyBetsError', error.message, { root: true }); // add mutation BP-16141
                console.error(`${action.COPY_BETS} Response Error`, [error, selectionIds]);
            });
    }),
    [_action.PLACE_BET]({ state, dispatch, commit, getters, rootState, rootGetters }, { bet, type }) {
        commit(coreMutation.START_LOAD, action.PLACE_BET, { root: true });
        dispatch(_action.RESET_BETSLIP_ERROR, type);

        const isVirtualSport = betslipType.VIRTUAL === type;
        const request = isVirtualSport ? betslipEndpoints.placeVirtualBet : betslipEndpoints.placeBet;

        Vue.$gtm.query({
            event: 'bet_place_attempt',
            bet_type: type,
            stake: bet.stake,
        });
        return Vue.$http
            .post(request, { ...bet, uuid: state[type].uuid })
            .then(({ data }) => {
                const updatedStatus = { ...data, Placed: isBetPlacedSucceed(data) };

                commit(_mutation.SET_BETSLIP_STATUS, { status: updatedStatus, type });

                if (updatedStatus.Placed) {
                    const { preference } = rootGetters[platformGetter.GET_SETTINGS];

                    commit(_mutation.GENERATE_BETSLIP_ID, type);

                    if (!preference.first_bet) {
                        commit(
                            platformMutation.UPDATE_PREFERENCE,
                            {
                                first_bet: new Date().toISOString(),
                            },
                            { root: true }
                        );
                    }

                    commit(coreMutation.END_LOAD, action.PLACE_BET, { root: true });
                    dispatch(authAction.GET_BALANCE, { force: true }, { root: true });
                    dispatch(_action.UPDATE_BETSLIP_PRICES, { type });
                    const currentPendingCount = rootGetters[sportGetter.GET_PENDING_BETS_COUNT];
                    if (!isVirtualSport) {
                        commit(sportMutation.UPDATE_PENDING_BETS_COUNT, { pendingBetsCount: currentPendingCount + 1 }, { root: true });
                    }
                } else {
                    commit(_mutation.RESET_BETSLIP_POLLING);
                    commit(_mutation.SET_BETSLIP_POLLING_START_TIMESTAMP);

                    dispatch(_action.BETSLIP_POLLING_ATTEMPT, {
                        attemptId: data.attemptId,
                        timeoutSeconds: data.timeoutSeconds,
                        type,
                    });
                }

                return data;
            })
            .catch((error) => {
                const { payload, message, errorCode, response } = error;
                const priceError = payload && !!(payload.changedPrices || payload.rejectedPrices);

                payload && commit(_mutation.SET_BETSLIP_STATUS, { status: payload, type });
                dispatch(_action.REJECT_BETSLIP, { payload: priceError && payload, type });
                dispatch(_action.SET_BETSLIP_ERROR, { message, type, code: errorCode || response.data.error });

                Vue.$gtm.query({
                    event: 'bet_place_error',
                    reason: message,
                    bet_type: type,
                    status: 'pre-live',
                });

                throw error;
            });
    },
    [_action.BETSLIP_POLLING_ATTEMPT]({ commit, state, dispatch, getters }, { attemptId, timeoutSeconds, type }) {
        timeoutSeconds = timeoutSeconds || 0;
        const TIME_SECOND = 1000;
        const timeout = TIME_SECOND * timeoutSeconds;
        const attempt = getters[_getter.GET_BETSLIP_POLLING_ATTEMPTS];

        if (attempt > BET_PLACEMENT_MAX_ATTEMPTS) {
            commit(coreMutation.END_LOAD, action.PLACE_BET, { root: true });
            commit(_mutation.BETSLIP_POLLING_CLEAR_INTERVAL);

            dispatch(_action.SET_BETSLIP_ERROR, {
                message: Vue.$t('ui.betslip.error.timeout'),
                type,
            });

            Vue.$gtm.query({
                event: 'bet_place_response_time_out',
                passedSeconds: toDiffInSeconds(getters[_getter.GET_BETSLIP_POLLING_START_TIMESTAMP]),
            });

            return;
        }

        commit(_mutation.UPDATE_BETSLIP_POLLING_TIMER, timeoutSeconds);
        commit(_mutation.BETSLIP_POLLING_CLEAR_INTERVAL);

        commit(
            _mutation.SET_BETSLIP_CURRENT_INTERVAL,
            setInterval(() => {
                commit(_mutation.UPDATE_BETSLIP_POLLING_TIMER);
            }, TIME_SECOND)
        );

        commit(
            _mutation.SET_BETSLIP_TIMEOUT_ID,
            setTimeout(() => {
                commit(_mutation.UPDATE_BETSLIP_POLLING_ATTEMPT, attempt + 1);
                dispatch(_action.GET_BETSLIP_STATUS, { attemptId, timeoutSeconds, type });
            }, timeout)
        );
    },
    [_action.DISABLE_BETSLIP_POLLING]({ commit, state }) {
        const betslip = getSelectedBetslip(state);
        if (betslip.pollingInterval) {
            clearInterval(betslip.pollingInterval);
            commit(_mutation.RESET_BETSLIP_POLLING);
        }
    },
    [_action.GET_BETSLIP_STATUS]({ state, commit, dispatch, getters }, { attemptId, timeoutSeconds, type }) {
        return Vue.$http
            .get(`${betslipEndpoints.getBetslipStatus}/${attemptId}`)
            .then(({ data }) => {
                const isPlacedBet = isBetPlacedSucceed(data);
                const updatedStatus = { ...data, Placed: isPlacedBet };

                commit(_mutation.SET_BETSLIP_STATUS, { status: updatedStatus, type });

                if (isPlacedBet) {
                    commit(coreMutation.END_LOAD, action.PLACE_BET, { root: true });
                    dispatch(authAction.GET_BALANCE, { force: true }, { root: true });
                    dispatch(_action.UPDATE_BETSLIP_PRICES, { type });
                    dispatch(_action.DISABLE_BETSLIP_POLLING);
                } else {
                    dispatch(_action.BETSLIP_POLLING_ATTEMPT, {
                        attemptId,
                        timeoutSeconds,
                        type,
                    });
                }
            })
            .catch(({ payload, errorCode, message }) => {
                errorCode && commit(_mutation.GENERATE_BETSLIP_ID, type);
                payload && commit(_mutation.SET_BETSLIP_STATUS, { status: payload, type });

                dispatch(_action.SET_BETSLIP_ERROR, { message, type, code: errorCode });
                dispatch(_action.REJECT_BETSLIP, { payload, type });
                dispatch(_action.DISABLE_BETSLIP_POLLING);

                Vue.$gtm.query({
                    event: 'bet_place_error',
                    reason: message,
                    status: 'live',
                    bet_type: type,
                });
            });
    },
    [_action.REJECT_BETSLIP]({ commit, dispatch }, { payload = {}, type }) {
        const { changedPrices, rejectedPrices } = payload;

        commit(coreMutation.END_LOAD, action.PLACE_BET, { root: true });

        dispatch(_action.UPDATE_BETSLIP_PRICES, { changedPrices, type });
        dispatch(_action.REJECT_BETSLIP_PRICES, { rejectedPrices, type });
    },
    [_action.UPDATE_BETSLIP_PRICES]({ state, commit, rootGetters }, { changedPrices = [], type }) {
        const minimumOddsForBonus = rootGetters[platformGetter.GET_MINIMUM_ODDS_FOR_BONUS];
        const updatedBets = [];
        const betslip = state[type];

        for (const bet of betslip.bets) {
            const changedPrice = changedPrices.find((price) => price.selectionId === bet.price.Id);
            if (changedPrice?.newPrice) {
                updatedBets.push({
                    ...bet,
                    previousPrice: {
                        price: bet.price.Price,
                        priceRaw: bet.price.PriceRaw,
                    },
                    price: {
                        ...bet.price,
                        Price: changedPrice.newPrice.toFixed(2),
                        PriceRaw: changedPrice.newPrice,
                        EligibleForBonus: changedPrice.newPrice >= minimumOddsForBonus,
                    },
                });
            } else if (changedPrice && !changedPrice.newPrice) {
                updatedBets.push({
                    ...bet,
                    price: {
                        ...bet.price,
                        Price: changedPrice.newPrice.toFixed(2),
                        PriceRaw: changedPrice.newPrice,
                    },
                });
            } else {
                updatedBets.push({
                    ...bet,
                    previousPrice: undefined,
                });
            }
        }
        commit(_mutation.SET_BETSLIP_BETS, { bets: updatedBets, type }); // reactor BP-16141
    },
    [_action.REJECT_BETSLIP_PRICES]({ state, commit }, { type = betslipType.REGULAR, rejectedPrices = [] }) {
        const betslip = state[type];
        const refreshedBets = betslip.bets.map((price) => {
            delete price.rejectPrice;
            return price;
        });

        if (!refreshedBets.length && !rejectedPrices.length) {
            return;
        }

        const updatedBets = refreshedBets.map((bet) => {
            const rejectPrice = rejectedPrices.find((price) => price.selectionId === bet.price.Id);

            if (!rejectPrice) {
                return bet;
            }

            return {
                ...bet,
                rejectPrice: true,
            };
        });

        commit(_mutation.SET_BETSLIP_BETS, { bets: updatedBets, type });
    },
    [_action.RESET_BETSLIP_STATUS]({ commit, getters }, payload) {
        if (!payload) {
            commit(_mutation.RESET_BETSLIP_STATUS, betslipType.REGULAR);
            commit(_mutation.RESET_BETSLIP_STATUS, betslipType.VIRTUAL);
        } else {
            const type = payload || getters[_getter.GET_BETSLIP_TYPE]; // reactor BP-16141
            commit(_mutation.RESET_BETSLIP_STATUS, type); // reactor BP-16141
        }
    },
    [_action.SET_BETSLIP_ERROR]({ state, commit }, { message, type, code = null }) {
        // reactor BP-16141
        commit(_mutation.SET_BETSLIP_ERROR, {
            message: helper.processErrorResponse({ message }, 'ui.common.error.failedToPlaceBet'),
            type, // reactor BP-16141
            code,
        });
    },
    [_action.RESET_BETSLIP_ERROR]({ state, commit, dispatch, getters }, type) {
        // reactor BP-16141
        if (type) {
            dispatch(_action.REJECT_BETSLIP_PRICES, { type }); // reactor BP-16141
            state[type].error.message && commit(_mutation.RESET_BETSLIP_ERROR, type); // reactor BP-16141
        } else {
            dispatch(_action.REJECT_BETSLIP_PRICES, { type: betslipType.REGULAR });
            dispatch(_action.REJECT_BETSLIP_PRICES, { type: betslipType.VIRTUAL });
            commit(_mutation.RESET_BETSLIP_ERROR, betslipType.VIRTUAL);
            commit(_mutation.RESET_BETSLIP_ERROR, betslipType.REGULAR);
        }
    },
    [_action.LOAD_SHARE_LINKS]: actionLoader(
        action.LOAD_SHARE_LINKS,
        ({ commit, state, dispatch, getters }, { selectionIds, shareText } = {}) => {
            const selectedBetsIds = selectionIds || getters[_getter.GET_BETSLIP_BOOKING_LAST_SELECTIONS];
            return dispatch(_action.CREATE_BOOKING_CODE, selectedBetsIds)
                .then(({ code }) => {
                    const directShareLink = directShareLinkTemplateFn('bookingCode', code);
                    const links = getBetSharingLinks(getSocialLinks(directShareLink, shareText));

                    commit(_mutation.SET_SHARE_LINKS, links);

                    return links;
                })
                .catch((error) => {
                    console.error(`${action.LOAD_SHARE_LINKS} Error`, [error]);
                });
        }
    ),
    [_action.CREATE_BOOKING_CODE]: actionLoader(action.CREATE_BOOKING_CODE, ({ commit, state }, selections) => {
        if (state.booking.created.code && JSON.stringify(selections) === JSON.stringify(state.booking.created.selectionIds)) {
            return Promise.resolve(state.booking.created);
        }
        return Vue.$http
            .post(betslipEndpoints.createBookingCode, { selections })
            .then(({ data }) => {
                commit(_mutation.SET_CREATED_BOOKING_CODE, {
                    code: data.code,
                    expires: data.expirationTimestamp,
                    selectionIds: selections,
                });
                return data;
            })
            .catch((error) => {
                commit(_mutation.SET_CREATED_BOOKING_CODE_ERROR, {
                    error: helper.processErrorResponse(error, 'ui.bookingCode.error'),
                    lastSelectionIds: selections,
                });
                throw error;
            });
    }),
    [_action.GET_BOOKING_SELECTION]: actionLoader(
        action.GET_BOOKING_SELECTION,
        ({ commit, dispatch, getters, rootGetters }, bookingCode) => {
            return new Promise((resolve, reject) => {
                Vue.$http
                    .get(`${betslipEndpoints.getBookingSelection}/${bookingCode}`)
                    .then(({ data }) => {
                        const { items } = data;
                        const { bets } = getters[_getter.GET_REGULAR_BETSLIP];
                        const minimumOddsForBonus = rootGetters[platformGetter.GET_MINIMUM_ODDS_FOR_BONUS];
                        const selectionsIds = items.map((it) => Number(it.event.id));
                        const filteredBets = bets.filter((bet) => !selectionsIds.includes(bet.eventId));
                        const selection = [...filteredBets, ...processBookingSelection(items, minimumOddsForBonus)];
                        const booking = {
                            selection,
                            code: bookingCode,
                        };

                        commit(_mutation.SET_BOOKING_SELECTION, booking);
                        if (items.length) {
                            commit(_mutation.SET_BETSLIP_BETS, { bets: selection, type: betslipType.REGULAR });
                        } else {
                            commit(_mutation.SET_BOOKING_SELECTION_ERROR, Vue.$t('errors.BN_EMPTY_REQUEST'));
                        }
                        resolve(booking);
                    })
                    .catch((error) => {
                        const message = error.errorCode ? error.message : Vue.$t('ui.common.error.unexpectedError');
                        commit(_mutation.SET_BOOKING_SELECTION_ERROR, message);
                        reject(error);
                    });
            });
        }
    ),
    [_action.LOAD_EXTERNAL_PREFILL_IDS]: actionLoader(action.LOAD_EXTERNAL_PREFILL_IDS, ({ commit, getters, rootGetters }, selections) => {
        return Vue.$http
            .post(pricing.getEventsPricesByIds, { selections })
            .then(({ data: { items } }) => {
                const incomingBets = items.map(priceItemAdapter(rootGetters[platformGetter.GET_MINIMUM_ODDS_FOR_BONUS]));
                const { bets } = getters[_getter.GET_REGULAR_BETSLIP];
                const incomingBetsMap = incomingBets.reduce((accumulator, it) => {
                    accumulator[Number(it.eventId)] = true;
                    return accumulator;
                }, {});

                const filteredOldBets = bets.filter((bet) => !incomingBetsMap[Number(bet.eventId)]);
                const newBets = [...filteredOldBets, ...incomingBets];
                commit(_mutation.SET_BETSLIP_BETS, {
                    bets: newBets,
                });

                return newBets;
            })
            .catch(() => {
                commit(_mutation.SET_EXTERNAL_PREFILL_ERROR, Vue.$t('ui.common.error.unexpectedError'));
            });
    }),
    [_action.LOAD_BOOKING_CODE_BY_QUERY]: actionLoader(action.LOAD_BOOKING_CODE_BY_QUERY, ({ dispatch, commit }, bookingCode) => {
        dispatch(_action.RESET_BETSLIP_STATUS);
        return dispatch(_action.GET_BOOKING_SELECTION, bookingCode).finally(() =>
            dispatch(generalAction.TOGGLE_BETSLIP_STATE, null, { root: true })
        );
    }),
    [_action.GET_SELECTIONS_BY_IDS]: actionLoader(action.GET_SELECTIONS_BY_IDS, ({ commit, state, rootGetters }, { selections, type }) => {
        const endpoint = betslipType.VIRTUAL === type ? pricing.getVirtualFullPrices : pricing.getEventsPricesByIds;
        return Vue.$http
            .post(endpoint, { selections })
            .then(({ data: { items } }) => {
                const currentSelections = items.map(priceItemAdapter(rootGetters[platformGetter.GET_MINIMUM_ODDS_FOR_BONUS]));
                commit(_mutation.SET_BETSLIP_BETS, {
                    bets: currentSelections,
                    type,
                });

                return currentSelections;
            })
            .catch((error) => console.error(`${action.GET_SELECTIONS_BY_IDS} Response Error`, [error]));
    }),
};

function priceItemAdapter(minimumOddsForBonus) {
    return (item) => {
        return {
            eventId: Number(item.event.id),
            name: item.event.name,
            rawDate: item.event.startTime,
            isLive: item.event.additionalInfo.live,
            market: {
                name: item.market.marketType.name,
                displayName: item.market.marketType.displayName,
                handicapType: item.market.handicapType,
                cashoutable: item.market.additionalInfo.cashoutable,
                twoUp: item.market.additionalInfo.twoUp,
            },
            price: {
                Name: item.price.name,
                Id: Number(item.price.id),
                Type: Number(item.market.marketType.id),
                Price: item.price.price && numberFormat(item.price.price),
                PriceRaw: item.price.price,
                EligibleForBonus: item.price.price >= minimumOddsForBonus,
                Hcp: item.price.handicap,
            },
        };
    };
}

function getSelectedBetslip(state) {
    return state[state[STORE_SELECTED_TYPE_PROPERTY]] || state[betslipType.REGULAR];
}

function isBetPlacedSucceed({ betslipId }) {
    return !!betslipId;
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

<template>
    <form class="page verify-or-forgot-page" data-test-id="contentBlock" @submit.prevent>
        <!-- Verify Account  -->
        <template v-if="isAccountVerification">
            <h2>{{ $t('ui.accountVerification.verifyAccount') }}</h2>

            <p class="sub-header verify-or-forgot-sub-header">
                <renderer
                    :input="
                        $t('ui.accountVerification.yourMobileIs', {
                            phoneNumber,
                            fullPhoneNumber,
                            routeName: changePhoneRoute,
                        })
                    "
                />
            </p>
        </template>

        <!-- Forgot password  -->
        <template v-else>
            <h2>{{ $t(`ui.common.${pinOrPassword}.forgotten`) }}</h2>

            <p class="sub-header verify-or-forgot-sub-header">
                {{ $t(`ui.resetPassword.enterYourMobileNumber`, { brandName: $t('project.brand') }) }}
            </p>

            <PhoneNumber
                data-test-id="forgotPhoneNumber"
                form-name="verify-or-forgot-form"
                :value="phoneNumber"
                :v="$v.phoneNumber"
                :placeholder="phoneNumberValidation.placeholder"
                :error-messages="phoneNumberValidation.errorMessages"
                @value="handlePhoneNumberChange"
            />
        </template>

        <template v-if="currentOtpOptions.length > 1">
            <div class="verify-or-forgot-bottom-line" />

            <h5 class="verify-or-forgot-selector-title">{{ $t('ui.accountVerification.verificationSelectorTitle') }}</h5>

            <OtpOptionsSelector
                data-test-id="otpOptionsBlock"
                class="verify-or-forgot-page-verification-selector"
                :otp-options="currentOtpOptions"
                :selected-otp-option="selectedOtpOption"
                @change="handleChangeOtpOption"
            />
        </template>

        <div v-if="otpOptionsError || otpOptionsWarning" class="verify-or-forgot-otp-error" :class="{ warning: otpOptionsWarning }">
            <renderer :input="otpOptionsError || otpOptionsWarning" class="notify error" />
        </div>

        <CaptchaWidget v-if="showCaptcha" :name="$options.name" class="verify-or-forgot-captcha-widget" @error="trackCloudflareFailures" />

        <Button
            data-test-id="handleSentVerificationCode"
            :disabled="isRequestButtonDisabled"
            :loading="isCodeVerificationProgress"
            @click="handleSentVerificationCode"
        >
            {{ $t('ui.accountVerification.getVerificationCode') }}
        </Button>

        <renderer v-if="error" class="notify error verify-or-forgot-error" :input="error" />
    </form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { helper } from '@/modules/core';
import { routeName } from '@/router/const-name';
import { user as userEndpoints } from '@/modules/platform/endpoints';
import { normalizeOtpOptions } from '@/modules/platform/utils/verification';
import { otpOptionsNames } from '@/modules/platform/const/verification';

import { getter as coreGetter } from '@/modules/core/store/const';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { action as authAction, getter as authGetter, mutation as authMutation } from '@/modules/platform/store/modules/auth/const';
import { getter as translationsGetter } from '@/store/modules/translations/const';

import Button from '@/modules/platform/components/content/Button.vue';
import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import PhoneNumber from '@/components/PhoneNumber';
import OtpOptionsSelector from '@/modules/platform/components/Verification/OtpOptionsSelector';

export default {
    name: 'VerifyOrForgot',
    components: { Button, PhoneNumber, OtpOptionsSelector },
    mixins: [PageMixin, SEOMixin],
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch(authAction.RESET_ERROR);

        this.$store.commit(authMutation.SET_PHONE_NUMBER, {
            phoneNumber: this.phoneNumber,
            phonePrefix: this.phonePrefix,
        });
        next();
    },
    props: {
        smsVerification: {
            type: String,
            default: undefined,
        },
        isAccountVerification: {
            type: Boolean,
            default: false,
        },
        changePhoneRoute: String,
    },
    data() {
        return {
            phoneNumber: null,
            otpOptionsWarning: '',
            otpOptionsError: '',
        };
    },
    validations() {
        return {
            phoneNumber: {
                required,
                ...this.phoneNumberValidation.validators,
            },
        };
    },
    computed: {
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            storedPhoneNumber: authGetter.GET_PHONE_NUMBER,
            phonePrefix: platformGetter.GET_PHONE_COUNTRY_CODE,
            pinOrPassword: translationsGetter.PIN_OR_PASSWORD,
            token: authGetter.SECURED_TOKEN,
            country: platformGetter.GET_COUNTRY,
            captchaError: authGetter.GET_CAPTCHA_ERROR,
            phoneNumberValidation: platformGetter.GET_PHONE_NUMBER_VALIDATION,
            otpOptions: platformGetter.GET_OTP_OPTIONS,
            userOtpOptions: authGetter.GET_USER_OTP_OPTIONS,
            selectedOtpOption: authGetter.GET_SELECTED_OTP_OPTION,
            authError: authGetter.GET_AUTH_ERROR,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
        }),
        isCodeVerificationProgress() {
            return this.isLoading(authAction.REQUEST_CODE);
        },
        fullPhoneNumber() {
            return helper
                .formatPhoneNumber(this.phoneNumber || '', this.phonePrefix, this.brandPreference.phoneNumberFormat)
                .replace(this.phonePrefix, `${this.phonePrefix} `);
        },
        error() {
            return this.captchaError || this.authError;
        },
        showCaptcha() {
            const { pathsWithCaptcha } = this.brandPreference;
            if (!pathsWithCaptcha) return false;

            return pathsWithCaptcha.includes(userEndpoints.resetPassword) || pathsWithCaptcha.includes('/' + userEndpoints.resetPassword);
        },
        isRequestButtonDisabled() {
            const captcha = this.showCaptcha && !this.token;

            return captcha || this.$v.$invalid || !this.selectedOtpOption || this.isCodeVerificationProgress;
        },
        currentOtpOptions() {
            // Here we return the OTP options for the user if they are available;
            // otherwise, we return the options for the brand.
            return this.userOtpOptions || normalizeOtpOptions(this.otpOptions);
        },
    },
    created() {
        this.$store.dispatch(authAction.RESET_ERROR);
        this.$store.dispatch(authAction.RESET_USER_OTP_OPTIONS);

        if (this.smsVerification) {
            this.$store.dispatch(authAction.VERIFY_ACCOUNT_BY_HASH, {
                hash: this.smsVerification,
            });
        }
    },
    mounted() {
        this.phoneNumber = this.storedPhoneNumber || this.$route.params.phoneNumber;

        this.setSelectedOtpOption(this.currentOtpOptions[0] ? this.currentOtpOptions[0].name : '');

        if (this.phoneNumberValidation.validators && this.phoneNumber) this.$v.phoneNumber.$touch();
    },
    methods: {
        ...mapMutations({
            setSelectedOtpOption: authMutation.SET_SELECTED_OTP_OPTION,
        }),
        resetUserOtpOptions() {
            this.otpOptionsError = '';
            this.otpOptionsWarning = '';
            this.$store.dispatch(authAction.RESET_USER_OTP_OPTIONS);

            if (this.currentOtpOptions[0]) {
                this.setSelectedOtpOption(this.currentOtpOptions[0].name);
            }
        },
        handlePhoneNumberChange(value) {
            this.phoneNumber = value;

            this.resetUserOtpOptions();
        },
        handleSentVerificationCode() {
            this.otpOptionsError = '';
            this.otpOptionsWarning = '';

            this.$store
                .dispatch(authAction.REQUEST_CODE, {
                    phoneNumber: this.phoneNumber,
                    resetMethodName: this.selectedOtpOption,
                    isVerificationCode: this.isAccountVerification || false,
                })
                .then(({ isSelectedLimitReached, isGlobalLimitReached, isCodeSent }) => {
                    if (isCodeSent) {
                        this.$router.push({
                            name: this.isAccountVerification ? routeName.VERIFICATION_CODE : routeName.RESET,
                            params: {
                                phoneNumber: this.phoneNumber,
                                selectedOtpOption: this.selectedOtpOption,
                            },
                        });

                        return;
                    }

                    if (isGlobalLimitReached) {
                        this.otpOptionsError = this.$t('ui.accountVerification.errors.globalLimitReached');
                        this.setSelectedOtpOption('');

                        return;
                    }

                    if (isSelectedLimitReached) {
                        this.otpOptionsWarning = `${this.$t(
                            `ui.accountVerification.errors.limitReachedFirstPart.${otpOptionsNames[this.selectedOtpOption]}`
                        )} ${this.$t('ui.accountVerification.errors.limitReachedSecondPart')}`;
                        this.setSelectedOtpOption(this.currentOtpOptions[0].name);

                        return;
                    }
                });
        },
        trackCloudflareFailures(reason) {
            this.$gtm.query({
                event: 'reset_password_cloudflare_failure',
                reason,
            });
        },
        handleChangeOtpOption(option) {
            this.setSelectedOtpOption(option);
        },
    },
};
</script>

<style scoped lang="scss">
.verify-or-forgot-page {
    border: 1px solid $dark-grey;
    margin: 20px;
    padding: 20px 16px 16px;
}

.verify-or-forgot-sub-header {
    margin-bottom: 12px;
}

.verify-or-forgot-selector-title {
    margin-top: 0px;
    margin-bottom: 12px;
}

.verify-or-forgot-page-verification-selector {
    margin-bottom: 16px;
}

.verify-or-forgot-bottom-line {
    height: 1px;
    background-color: $dark-grey;
    margin-bottom: 12px;
}

.verify-or-forgot-otp-error {
    display: table;
    margin-bottom: 16px;

    .error {
        display: table-cell;
        margin: 0;
    }

    &.warning {
        .error {
            background-color: #ffe39e;
        }
    }
}

.verify-or-forgot-captcha-widget {
    margin-bottom: 16px;
}

.verify-or-forgot-error {
    margin: 16px 0 0;
}
</style>

<template>
    <div class="content" :class="{ 'is-home-page': isHomePage }">
        <Headline v-if="isHomePage" :title="title" :is-category="true" :icon="iconId" />
        <CasinoCollectionTitle
            v-else-if="title"
            :games-count="gamesCount"
            :games-limit="gamesLimit"
            :show-view-all="showViewAll"
            :title="title"
            :icon-id="iconId"
            @openCasinoGames="openCasinoGames"
        />

        <div v-if="games.length > 0" class="casino-list" :style="casinoListStyle">
            <CasinoCollectionGameTile v-for="game in games" :key="game.id" :game="game" />
        </div>
        <CasinoFavouritesEmpty v-else-if="isFavourites && games.length === 0" />
        <CasinoShowAllGames v-if="showMoreGamesOnBottom" />
    </div>
</template>

<script>
import CasinoCollectionGameTile from '@/modules/casino/strapiComponents/components/CasinoCollectionGameTile.vue';
import CasinoCollectionTitle from '@/modules/casino/strapiComponents/components/CasinoCollectionTitle.vue';
import CasinoFavouritesEmpty from '@/modules/casino/components/CasinoFavouritesEmpty.vue';
import Headline from '@/components/Headline.vue';
import CasinoShowAllGames from '@/components/content/CasinoShowAllGames.vue';

export default {
    name: 'CasinoCollectionGrid',
    components: { CasinoShowAllGames, Headline, CasinoFavouritesEmpty, CasinoCollectionGameTile, CasinoCollectionTitle },
    props: {
        games: Array,
        gamesCount: Number,
        gamesLimit: Number,
        showViewAll: Boolean,
        title: String,
        iconId: String,
        isFavourites: Boolean,
        isHomePage: Boolean,
        showMoreGamesOnBottom: Boolean,
        columnCount: Number,
    },
    computed: {
        casinoListStyle() {
            return `grid-template-columns: repeat(${this.columnCount}, minmax(0, 1fr));`;
        },
    },
    methods: {
        openCasinoGames() {
            this.$emit('openCasinoGames');
        },
    },
};
</script>

<style scoped lang="scss">
.content {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 0 12px;

    &.is-home-page {
        padding: 0;
        margin-top: 0;
        .casino-list {
            padding: 0 12px;
        }
    }

    .casino-list {
        display: grid;
        width: 100%;
        gap: 12px;
        margin-top: 12px;
    }
}
</style>
